import React from 'react';
import { Edit, SimpleForm, TextField, SelectInput, FormDataConsumer, TextInput } from 'react-admin';
import Title from './Title';

const choices = [
  { statusCode: "1", statusLabel: "Skickad" },
  { statusCode: "2", statusLabel: "Bearbetas" },
  { statusCode: "3", statusLabel: "Avslutad" },
];

const FilteredSelectField = ({ record = {} }) => {
  let leastStatus = record.currentStatus || 1;
  console.log(leastStatus);
  let newChoices = choices.filter(c => c.statusCode >= leastStatus).map(c=> {
    c.statusCode = c.statusCode;
    return c;
  });

  return <SelectInput source="currentStatus" format={v=> v? v.toString() : ''} parse={v=> Number(v)}  choices={newChoices} optionValue="statusCode" optionText="statusLabel"  />;
};

const EditClient = (props) => (
  <Edit {...props} title={<Title />}>
     <SimpleForm>
        <TextField source="RequestId" />
        <FilteredSelectField source="currentStatus" />
          <FormDataConsumer>
            {({ formData, ...rest }) => formData.currentStatus === 3 &&
              <TextInput source="closingComment" {...rest} />
            }
          </FormDataConsumer>
      </SimpleForm>
   </Edit>
);

export default EditClient;
