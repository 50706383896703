import React from 'react';
import WaitScreen from './WaitScreen';
import FlowStep from './FlowStep';
import StepEditor from './StepEditor';
import NodeEditor from './NodeEditor';
import '../floweditor.scss';
import { DragDropContext } from 'react-beautiful-dnd';
import axios from 'axios';
import qs from "query-string";
import { withRouter } from "react-router-dom";
import config from "../../../config.json";
import { Authenticated } from 'react-admin';
import { Auth, API } from 'aws-amplify';

class FlowEditor extends React.Component {
  constructor(props) {
    super(props);
    var flowVersionId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).flowVersionId;
    this.state = {
      flow: {
        FlowVersionId: flowVersionId,
        steps: []
      },
      editStep: null,
      editNode: null,
      isLoaded: false,
      isLoadingFlow: false,
      failed: false,
      showSettings: false,
      showClientFlows: false
    };
    const apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiNameProd ;

    const api = {
      apiName: apiName,
      resources: {
        flowVersion: 'flowVersion',
      },
      resourceUris: {
        flowVersion: '/flowVersion',
        clientFlowVersion: '/clientFlowVersion',
      }
    };
    this.api = api;

  }
  initializeCurrentFlow() {
    var flowVersionId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).flowVersionId;
    let apiName = this.api.apiName;
    if (flowVersionId) {
      API.get(apiName, this.api.resourceUris.flowVersion + '/' + flowVersionId).then(response => {
        var flow = response;
        if (flow.FlowVersionId) {
          flow.steps = flow.steps || [];
          flow.steps.map(s => {
            s.uid = Date.now() + '-' + s.stepNumber;
            return s;
          });
          flow.variables = flow.variables || [];

          // if (flow.variables.length > 0) {

          if (flow.variables.indexOf('email') === -1) {
            flow.variables.push('email');
          }
          if (flow.variables.indexOf('firstName') === -1) {
            flow.variables.push('firstName');
          }
          if (flow.variables.indexOf('lastName') === -1) {
            flow.variables.push('lastName');
          }
          if (flow.variables.indexOf('linkedIn') === -1) {
            flow.variables.push('linkedIn');
          }
          if (flow.variables.indexOf('phone') === -1) {
            flow.variables.push('phone');
          }
          // }
          this.setState({ flow: flow, isLoaded: true, isLoadingFlow: false });
        };
        API.get(apiName, this.api.resourceUris.clientFlowVersion + '/?filter=' + flowVersionId)
          .then(response => {
            let flows = response;
            this.setState({ clientFlows: flows });
          });
      });
    }
    else {
      this.setState({ failed: true });
    }
  }

  componentDidMount() {
    this.initializeCurrentFlow();
  }
  setFlowSteps(flow, steps) {
    flow.steps = steps.map(s => {
      s.uid = Date.now() + '-' + s.stepNumber;
      return s;
    })
    return flow;
  }

  resetToCurrentFlow() {
    this.setState({ isLoadingFlow: true }, this.initializeCurrentFlow)
  }
  loadClientFlow(clientFlow) {
    let path = this.api.resourceUris.clientFlowVersion + '/' + `${clientFlow.FlowVersionId}_${clientFlow.timestamp}`;
    this.setState({ isLoadingFlow: true }, () => {
      API.get(this.api.apiName, path).then(response => {
        let { flow } = this.state;
        let clientFlow = response;

        flow = this.setFlowSteps(flow, clientFlow.steps);

        this.setState({ flow: flow, isLoadingFlow: false });
      });
    });
  }

  // Step Functions
  addStep() {
    let { flow } = this.state;
    let steps = flow.steps || [];

    steps.push({
      name: "Untitled Step",
      stepNumber: (steps.length + 1)
    });
    this.setState({ flow: flow });
  }
  editStep(step) {
    this.setState({ editStep: step });
  }

  saveStep() {
    this.setState({ editStep: null, editNode: null });
    this.forceUpdate();
  }
  deleteStep(step, ) {
    let { flow } = this.state;

    let { steps } = flow;
    let nodeIds = step.nodes.map(node => node.id);
    // release all links to other steps
    steps.forEach(s => {
      s.nodes.forEach(node => {
        node.actions.forEach(action => {
          if (action.actionType === 'goToNode') {
            if ((nodeIds.indexOf(action.nextNodeId) > -1) || (nodeIds.indexOf(node.id) > -1)) {
              // if the gotonode points to a node in the step, or if
              // the node is in the step and pointing to a node in another step
              delete action.nextNodeId;
            }
          }
          if (action.actionType.toLowerCase().indexOf("question") > -1) {
            if (action.content && action.content.answers) {
              action.content.answers.forEach(answer => {
                if ((nodeIds.indexOf(answer.nextNodeId) > -1) || (nodeIds.indexOf(node.id) > -1)) {
                  // if the question points to a node in the step or 
                  // if the node is in the step and the action points to a step
                  // in another node
                  delete answer.nextNodeId;
                }
              });
            }
          }
        });
      });
    });
    const stepNumber = Number(step.stepNumber);
    this.setState({ flow: flow }, () => {
      steps = steps.filter(item => item !== step);

      steps = steps.map(item => {
        item.stepNumber = item.stepNumber > stepNumber ? item.stepNumber - 1 : item.stepNumber;
        return item;
      });
      flow.steps = steps;

      this.setState({ flow: flow }, () => {});
    });

  }

  // Node Functions
  editNode(editNode) {
    this.setState({ editNode: editNode });
  }

  deleteNode() {
    let { editNode } = this.state;
    let step = editNode.step;
    step.nodes = step.nodes.filter(item => item !== editNode.node);
    this.setState({ editNode: null });
  }

  getNodeById(flow, nodeId) {
    const nodes = flow.steps.map(s => { return s.nodes }).reduce((n, nn) => { return n.concat(nn) });
    return nodes.find(node => { return node.id.toString() === nodeId.toString() });
  }

  getActionRow(action, index) {
    switch (action.actionType) {
      case 'question':
        return action.content.answers[index];
      case 'goToNode':
        return action;
      default:
        return action.content.answers[index];
    }
  }

  onDragUpdate({ destination }) {
    if (destination) {
      let stepNumber = Number(destination.droppableId.split('-')[0].split('_')[1]);
      let { flow } = this.state;

      flow.steps.map(s => {
        s.isDragging = s.stepNumber === stepNumber ? true : undefined;
        return s;
      });

      this.setState({ flow: flow });

    }
  }

  onBeforeDragStart(result) {
    if (result && result.type && result.source && result.source.droppableId &&
      result.type === 'step-node') {
      let { flow } = this.state;
      let stepNumber = Number(result.source.droppableId.split('-')[0].split('_')[1]);
      flow.steps = flow.steps.map(s => {
        s.isDragging = s.stepNumber === stepNumber ? true : undefined;
        return s;
      });

      this.setState({ flow: flow });
    }
    else if (result && result.type && result.source &&
      result.source.droppableId && result.type === 'pointer') {
      let { flow } = this.state;
      const nodeId = result.source.droppableId.split('_')[0];
      flow.steps.forEach(s => {
        s.nodes = s.nodes.map(node => {
          node.isDragging = node.id === nodeId ? true : undefined;
          return node;
        });
      });
      this.setState({ flow: flow });
    }
  }

  onDragEnd(result) {
    let { flow } = this.state;
    if (result.draggableId.indexOf('node_drg') > -1 && result.destination) {
      let split = result.destination.droppableId.split('-')
      if (split.length == 2) {
        const stepNumber = Number(split[0].split('_')[1]);
        const lane = Number(split[1].split('_')[1]);
        const nodeId = result.draggableId.split('_')[2];


        let nodeI = null;
        let stepI = null;


        let node = this.getNodeById(flow, nodeId);
        // flow.steps
        flow.steps.forEach((s, stepIndex) => {
          s.nodes = s.nodes.filter(n => n.id !== node.id);
        });

        let step = flow.steps.find(s => s.stepNumber === stepNumber);
        node.lane = lane;
        step.nodes.splice(result.destination.index, 0, node);

        this.setState({ flow: flow }, () => {

        });

      }
      // it's a node
    }
    else {
      // its a pointer
      if (result.draggableId && result.destination && result.source) {
        if (result.source.droppableId && result.destination.droppableId) {

          const ids = result.draggableId.split('_');
          let nodeId = ids[0];
          let actionIndex = ids[1];
          let actionRowIndex = ids[2];

          let node = this.getNodeById(flow, nodeId);
          let action = node.actions[actionIndex];
          let actionRow = this.getActionRow(action, actionRowIndex);

          actionRow.nextNodeId = result.source.droppableId === result.destination.droppableId ? null : result.destination.droppableId;

        }
      }

      // clear all isDragging
      flow.steps.forEach(s => {
        s.nodes = s.nodes.map(node => {
          node.isDragging = undefined;
          return node;
        });
      });
      this.setState({ flow: flow }, () => {

      });
    }

    // clear any step isdragging
    flow.steps.map(s => {
      delete s.isDragging;
      return s;
    });

    this.setState({ flow: flow }, () => {});

  }
  getEditor() {
    if (this.state.editStep) {
      return <StepEditor step={this.state.editStep} saveStep={this.saveStep.bind(this)} deleteStep={this.deleteStep.bind(this)}/>;
    }
    else if (this.state.editNode) {
      return <NodeEditor editNode={this.state.editNode} saveStep={this.saveStep.bind(this)} deleteNode={this.deleteNode.bind(this)} variables={this.state.flow.variables} flowType={this.state.flow.flow.flowType} />;
    }
    else {

    }
  }
  getComponentEditorClass() {
    let className = 'component-editor';
    className = (this.state.editNode || this.state.editStep) ? 'component-editor show' : 'component-editor';
    return className;
  }
  getCanvasClass() {
    let className = 'canvas';
    className = (this.state.editNode || this.state.editStep) ? 'canvas move' : 'canvas';
    return className;
  }

  saveFlow() {
    const put = { body: this.state.flow };
    this.setState({ isSaving: true }, () => {

      API.put(this.api.apiName, this.api.resourceUris.flowVersion + '/' + this.state.flow.FlowVersionId, put)
        .then(response => {
          let flow = response;
          if (flow.FlowVersionId) {
            this.props.history.push(`/flowVersion/${flow.FlowVersionId}/show`);
          }
        });
    });
  }
  toggleSettings() {
    this.setState({ showSettings: !this.state.showSettings });
  }
  toggleClientFlows() {
    this.setState({ showClientFlows: !this.state.showClientFlows });
  }
  addVariable(event) {
    let { flow } = this.state;
    flow.variables.push("New Variable");
    this.setState({ flow: flow });
  }
  removeVariable(event) {
    let { flow } = this.state;
    let i = event.target.getAttribute('data-index');
    flow.variables.splice(Number(i), 1);
    this.setState({ flow: flow });
  }

  handleVariableChange(event) {
    let { flow } = this.state;
    flow.variables[event.target.name] = event.target.value;
    this.setState({ flow: flow });
  }
  isEditable(variable) {
    switch (variable) {
      case 'email':
        return false;
      case 'lastName':
        return false;
      case 'firstName':
        return false;
      case 'linkedIn':
        return false;
      case 'phone':
        return false;
      default:
        return true;
    }
  }
  logFlow() {
    console.log(this.state.flow);
  }

  refCallback = element => {
    if (element) {
      this.setState({
        svgW: element.getBoundingClientRect().width.toFixed(0),
        svgH: element.getBoundingClientRect().height.toFixed(0),
      });
    }
  };

  render() {
    let { flow } = this.state;
    let s = `.arrow-container > svg {
width:${this.state.svgW+'px'}!important;
height:${this.state.svgH+'px'}!important;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  height: 100%;
}
#root>div {
  height: 100%;
}

`;
    return (
      this.state.isLoaded &&
      <Authenticated >
    
        <div className="flowEditor">
        {this.state.isSaving && (
          <WaitScreen message="Saving" />
        )}
        {this.state.isLoadingFlow && (
          <WaitScreen message="Loading" />
        )}
            <div className={this.getCanvasClass()}>
                <header>
                  <div className="flow-type">
                    {flow.flow.flowType} flow
                  </div>
                  <div className="flow-title">
                      {flow.name}
                  </div>
                </header>
                <div className="step-wrapper" ref={this.refCallback}>
                  <DragDropContext 
                    onDragEnd={this.onDragEnd.bind(this)}
                    onDragUpdate={this.onDragUpdate.bind(this)}
                    onBeforeDragStart={this.onBeforeDragStart.bind(this)}
                  >
                    {
                      flow.steps.map((step,index) => {
                        return (
                               <FlowStep step={step}
                                key={step.stepNumber}
                                editStep={this.editStep.bind(this)}
                                editNode={this.editNode.bind(this)}
                                deleteStep={this.deleteStep.bind(this)}
                                >
                                </FlowStep>
                           );
                         }
                      )
                    }
                  </DragDropContext>
                  <div className="flow-step pseudo">
                    <div className="nodes">
                      <a id="add-step" onClick={this.addStep.bind(this)}><i className="material-icons">add_circle_outline</i>Add step</a>
                    </div>
                    <div className="menu">
                    </div>
                  </div>
                </div>
            </div>
            <div className={this.getComponentEditorClass()}>
              {this.getEditor()}
            </div>
            <div className="floating-menu">
              {this.state.showSettings && 
              <div id="settings">
                <h2>Settings</h2>
                <h3>Custom Fields</h3>
                <ul>
                  {flow.variables.map((variable, i)=> {
                    const editable = this.isEditable(variable);
                    return (
                      <li key={i}>
                        <input className="variable" 
                          name={i} 
                          onChange={this.handleVariableChange.bind(this)} 
                          value={variable} 
                          disabled={editable? '':'disabled'}
                        />
                        {editable && <i className="material-icons"
                            onClick={this.removeVariable.bind(this)} 
                            data-index={i}>cancel</i>
                        }
                      </li>
                    );
                  })}
                  <li>
                    <i onClick={this.addVariable.bind(this)} className="material-icons">add_circle_outline</i>
                  </li>
                </ul>
              </div>}
              {this.state.showClientFlows && 
              <div id="client-flows">
                <h2>ClientFlows</h2>
                <ul>
                    <li><span>Current Flow</span><button><i onClick={()=> this.resetToCurrentFlow()}className="material-icons">open_in_browser</i></button></li>
                  {this.state.clientFlows && this.state.clientFlows.map((clientFlow, i)=> {
                    var date = new Date(clientFlow.timestamp);
                    return (<li key={i}><span>{date.toLocaleString()}</span><button><i onClick={()=> this.loadClientFlow(clientFlow)}className="material-icons">open_in_browser</i></button></li>
                    );
                  })}
                </ul>
              </div>}
              <div id="floating-btns">
                {this.state.clientFlows && this.state.clientFlows.length > 0 &&
                <div className="floating-btn settings-btn" onClick={this.toggleClientFlows.bind(this)}>
                  <i className="material-icons">timeline</i>
                  <span>Client flows</span>
                </div>
                }
                <div className="floating-btn settings-btn" onClick={this.toggleSettings.bind(this)}>
                  <i className="material-icons">settings</i>
                  <span>Settings</span>
                </div>
                <div className="floating-btn save-btn" onClick={this.saveFlow.bind(this)}>
                  <i className="material-icons">save_alt</i>
                  <span>Save flow</span>
                </div>
                <style>
                  {s}
                </style>
              </div>
            </div>
          </div>
        </Authenticated>
    );
  }
}
export default withRouter(FlowEditor);
