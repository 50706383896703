import React from 'react';
import { Create, SimpleForm, TextInput, ArrayInput, SimpleFormIterator, ReferenceInput, ReferenceArrayInput, AutocompleteArrayInput, BooleanInput, FormDataConsumer, SelectInput, LongTextInput } from 'react-admin';
import { parse } from "query-string";
import Title from './Title';
import languageChoices from '../../shared-components/languageCodes';

const statusChoices = [
  { status: "Live" },
  { status: "Paused" },
  { status: "Archived" }
];

const CreateJob = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { botId: BotId } = parse(props.location.search);
  const redirect = BotId ? `/bot/${BotId}/show/jobs` : 'show';
  const df = { BotId: BotId };
  return (
    <Create {...props} title={<Title />}>
      <SimpleForm
          defaultValue={ df }
          redirect={redirect}
      >
      <TextInput source="title" />
        <TextInput source="city" />
        <ReferenceArrayInput label="Job categories" reference="jobCategory" source="jobCategories">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <LongTextInput label="Link to job" source="link" />
        <BooleanInput source="useRegex" defaultValue={false} label="Use Regex for Linkmatching" />
        <BooleanInput source="overrideTemplate" defaultValue={false} />
        <BooleanInput source="disableApplications" defaultValue={false} />
        <BooleanInput source="openApplication" defaultValue={false} />
        <SelectInput source="status" choices={statusChoices} optionText="status" optionValue="status" defaultValue="Live"/>
        <BooleanInput source="overrideTemplate" defaultValue={false} />
            <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => formData.overrideTemplate &&
              <ArrayInput source="overrideTemplates" label="Template Overrrides" {...rest}>
                <SimpleFormIterator>
                    <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" {...rest}/>
                    <ReferenceInput
                      source="overrideFlowVersionId"
                      reference="flowVersion"
                      label="Flow Version">
                      <SelectInput optionText="name" optionValue="FlowVersionId" />
                    </ReferenceInput >
                  </SimpleFormIterator>
              </ArrayInput>          
            }
            </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default CreateJob;
