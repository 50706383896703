import React from 'react';
import { List, Datagrid, TextField, BooleanField, DateField, CardActions, RefreshButton, Filter, TextInput } from 'react-admin';

const Title = ({ record }) => {
    return <span>Bot Implementations</span>;
};

const BotImplementationActions = props => (
    <CardActions>
        <RefreshButton />
    </CardActions>
);

const ListBotImplementationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Client" source="clientProject.client.name" alwaysOn />
    </Filter>
);

export const ListBotImplementation = props => (
    <List {...props} actions={<BotImplementationActions />} title={<Title />} 
    filters={<ListBotImplementationFilter />}>
        <Datagrid rowClick="show" >
            <TextField label="Client" source="clientProject.client.name" />
            <TextField label="Project" source="clientProject.name" />
            <TextField source="name" />
            <BooleanField label="Use NLP" source="useNlp" />
            <TextField label="Bot type" source="type" />
            <DateField label="Created" source="createdDate" />
        </Datagrid>
    </List>
);
export default ListBotImplementation;
