import React from 'react';
import { ShowController, ShowView, SimpleShowLayout, TextField, ReferenceManyField, Tab, TabbedShowLayout, Datagrid, BooleanField, ArrayField, SelectField } from 'react-admin';
import AddBotImplementationButton from './AddBotImplementationButton';
import AddJobButton from './AddJobButton';
import Title from './Title';
const botTypes = [
  { type: "Lead", value: "lead" },
  { type: "Application", value: "application" },
];
const ViewBot = (props) => (
  <ShowController {...props} title={<Title />}>
     {controllerProps =>
        <ShowView {...props} {...controllerProps}>
          <TabbedShowLayout>
          <Tab label="Summary">
            <SimpleShowLayout>
                <TextField source="name" />
                <SelectField label="Bot type" source="type" 
                  choices={botTypes} optionText="type" 
                  optionValue="value" 
                />
                
            </SimpleShowLayout>
          </Tab>
          <Tab label="Bot Implementations" path="botImplementations">
            <ReferenceManyField
              addLabel={false}
              reference="botImplementation"
              target="botId"
            >
              <Datagrid rowClick="show">
                <TextField source="name" />
                <TextField label="type" source="type" />
                <TextField label="NLP" source="useNlp" />
              </Datagrid>
            </ReferenceManyField>
            <AddBotImplementationButton />
          </Tab>
          {controllerProps.record &&  controllerProps.record.type !== 'lead' &&
            <Tab label="Jobs" path="jobs">
              <ReferenceManyField
                addLabel={false}
                reference="job"
                target="botId"
              >
                <Datagrid rowClick="show">
                  <TextField source="title" />
                  <TextField source="city" />
                  <TextField source="status" />
                  <BooleanField source="openApplication" />
                  <BooleanField source="overrideTemplate" />
                </Datagrid>
              </ReferenceManyField>
              <AddJobButton />
            </Tab>
          }
        </TabbedShowLayout>
      </ShowView>
     }
    </ShowController>
);

export default ViewBot;
