import React from 'react';
import CreateIntegrationJob from "./CreateIntegrationJob.js"
import {
  List,
  Datagrid,
  TextField,
  CardActions,
  RefreshButton,
  ReferenceField,
  ReferenceFieldController,
  ReferenceArrayField,
  SingleFieldList,
  ChipField
}
from 'react-admin';

const Title = ({ record }) => {
    return <span>Jobs</span>;
};

const JobProjectsActions = props => (
  <CardActions>
    <RefreshButton />
  </CardActions>
);

const AddJobPanel = (props) => (
  <CreateIntegrationJob {...props} />
);

// <TextField source="ProjectId" />
export const ListJobProjects = props => (
  <List {...props} actions={<JobProjectsActions {...props} />} title={<Title />} >
    <Datagrid expand={<AddJobPanel/>}>
      <ReferenceFieldController label="Client" reference="bot" source="BotId" linkType={false}>
      {({referenceRecord, ...props}) => (
        <ReferenceField basePath="/bot"  label="Client" source="ProjectId" reference="clientProject" linkType={false} record={referenceRecord || {}} >
          <TextField source="client.name" />
        </ReferenceField>
      )}
      </ReferenceFieldController>
      <ReferenceArrayField label="Categories" reference="jobCategory" source="jobCategories">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="title" />
      <TextField source="city" />
      <TextField source="IntegrationJobId" />
    </Datagrid>
  </List>
);
export default ListJobProjects;