import React from 'react';
import { SelectInput } from 'react-admin';

const WorkoSelector = ({ name, label, dataProvider, source, scopedFormData, ...rest }) => {
    const options = [{
            label: 'Preferences',
            value: 'preferences'
        },
        {
            label: 'Preferred Organization Sizes',
            value: 'preferredOrganizationSizes'
        },
        {
            label: 'Current Role',
            value: 'jobTitle'
        },
        {
            label: 'Available in',
            value: 'availableIn'
        },
        {
            label: 'Preferred Employee Types',
            value: 'preferredEmployeeTypes'
        },
        {
            label: 'Industry',
            value: 'industry'
        },
        {
            label: 'Region',
            value: 'region'
        },
        {
            label: 'Tags',
            value: 'tags'
        }
    ];

    return (
        <SelectInput
      source={source}
      choices={options}
      optionText="label"
      optionValue="value"
      label={label}
    />
    );
};

export default WorkoSelector;
