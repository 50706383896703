import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { parse } from "query-string";
import Title from './Title';
import languageChoices from '../../shared-components/languageCodes';

const CreateFlowVersion = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { flowId: FlowId } = parse(props.location.search);
  const redirect = FlowId ? `/flow/${FlowId}/show/versions` : 'show';
  const df = { FlowId: FlowId };
  return (
    <Create {...props} title={<Title />}>
      <SimpleForm
          defaultValue={ df }
          redirect={ redirect }
      >
        <TextInput source="name" />
        <TextInput source="description" />
        <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
      </SimpleForm>
    </Create>
  );
};

export default CreateFlowVersion;
