import React from 'react';
import { Show, SimpleShowLayout, TextField, Tab, TabbedShowLayout, BooleanField, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import Title from './Title';

const ViewJob = (props) => (
  <Show {...props} title={<Title />}>
    <TabbedShowLayout>
        <Tab label="Summary">
          <SimpleShowLayout>
            <TextField source="title" />
            <ReferenceArrayField label="Categories" reference="jobCategory" source="jobCategories">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="city" />
            <TextField source="link" />
            <BooleanField source="useRegex" label="Use Regex for Linkmatching" />
            <BooleanField source="overrideTemplate" />
            <BooleanField source="disableApplications" />
            <BooleanField source="openApplication" />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export default ViewJob;
