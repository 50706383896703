import React from 'react';
import { TextField,Labeled } from 'react-admin';

const FamilyNameField = ({ record, ...rest }) =>
    record.attr.name ? (
        <Labeled label="Last Name">
            <TextField source="attr.family_name" record={record} {...rest} />
        </Labeled>
        ) : null;

export default FamilyNameField;
