import React from 'react';
import uuid from 'uuid';
import FlowNode from './FlowNode.js';
import { Draggable, Droppable } from 'react-beautiful-dnd';

class FlowStep extends React.Component {
  constructor(props) {
    super(props);
    let step = this.props.step;
    step.nodes = step.nodes || [];
    this.state = {
      editName: false
    };
    this.innerRef = React.createRef();
  }

  addNode() {
    let { step } = this.props;
    let nodes = step.nodes || [];

    const count = nodes.length + 1;
    nodes.push({
      name: 'Node ' + count,
      id: uuid.v4(),
      actions: []
    });
    this.setState({ step: step });
  }
  editStep() {
    this.props.editStep(this.props.step);
  }
  deleteStep() {
    let { step } = this.props;
    this.props.deleteStep(step);
  }
  editNode(node) {
    this.props.editNode({ step: this.props.step, node: node });
  }
  toggleEditName() {
    this.setState({ editName: !this.state.editName }, () => {
      if (this.state.editName) {
        this.nameInput.focus();
      }
    });
  }
  leaveEditName() {
    this.setState({ editName: false });
  }
  handleChange(event) {
    let { step } = this.props;
    step[event.target.name] = event.target.value;
    this.setState({ step: step });
  }
  getAddNodeButton() {
    const { step } = this.props;
    const btn = <button className="btn" onClick={this.addNode.bind(this)}>Add Node</button>;
    if (step.stepNumber === 1) {
      return ((step.nodes.length < 1) && btn) || '';
    }
    else {
      return btn;
    }
  }
  getHeader(step) {
    return this.state.editName ? (
      <div className="header">
        <div>
          <input value={step.name} ref={(input) => { this.nameInput = input; }} 
              name="name"
              autoComplete="off"
              onChange={this.handleChange.bind(this)}
              onBlur={this.leaveEditName.bind(this)}
          />
        </div>
      </div>
    ) : (
      <div className="header">
        <h2 onClick={this.toggleEditName.bind(this)} >{step.name}</h2>
      </div>);
  }
  getLanes(nodes) {
    let lanes = {};
    nodes.forEach(n => {
      n.lane = n.lane || 0;
      lanes[n.lane] = lanes[n.lane] || { nodes: [] };
      lanes[n.lane].nodes.push(n);
    });
    return lanes;
  }
  render() {
    let { step } = this.props;
    let lanes = this.getLanes(step.nodes);
    return (
      (
        <div className={ step.isDragging ? 'flow-step is-dragging':'flow-step'} id={"flow-step-"+step.stepNumber}>
          { this.getHeader(step) }
          <div className="nodes">
          {Object.keys(lanes).map((lane) => { return (
            <Droppable key={lane} droppableId={('step_' + step.stepNumber+'-lane_'+lane)} type={"step-node"} >
             {provided1 => (
              <div className="lane"
                ref={provided1.innerRef}
                    {...provided1.droppableProps}
              >
              <div style={{"display":"none"}}>
                {provided1.placeholder}
              </div>
              {
                lanes[lane].nodes.map((node,index) => {
                  return(
                    <Draggable  draggableId={'node_drg_'+node.id}
                    index={index}
                    type="node"
                    key={'drg'+node.id}
                    >
                        {provided2 => (
                          
                          <div
                            ref={provided2.innerRef}
                            {...provided2.draggableProps}
                            {...provided2.dragHandleProps}
                            className="flow-node-wrapper"
                            >
                              <Droppable key={'drp_' +node.id} droppableId={node.id} type="pointer">
                                {provided3 => (
                                  <FlowNode
                                  provided={provided3}
                                  innerRef={provided3.innerRef}
                                  node={node} editNode={this.editNode.bind(this)} />
                                )}
                              </Droppable>
                          </div>
                        )}
                    </Draggable>
                  );
                })
              }
            </div> 
            )}
            </Droppable>
            )}
          )}
          <div className={step.isDragging && (step.nodes && step.nodes.length > 1) ? 'new-lane is-dragging':'new-lane'} >
          <Droppable key={step.uid} droppableId={('step_' + step.stepNumber+'-lane_'+Object.keys(lanes).length)} type={"step-node"}>
            {(provided3,snapshot) => (
              <div className="dropzone"
                ref={provided3.innerRef}
              {...provided3.droppableProps}
                  
              >
              NEW LANE
                   {provided3.placeholder}
            {snapshot.isDraggingOver && (
              <div className="placeholder" style={{
                  "background":"0f0"
                }}
              ></div>
            )}
              </div> 
            )}
            </Droppable>
          </div> 
          </div> 
            <div className = "menu" > { this.getAddNodeButton() }
              <button className="btn" onClick={()=> this.deleteStep(step)}>Delete Step</button> 
            </div>  
          </div>
      )
      // <a className = "btn" onClick = { this.editStep.bind(this) } > Edit Step </a> 
    );
  }
}
export default FlowStep;
