import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import Title from './Title';

const flowTypes = [
    { type: "Lead", value: "lead" },
    { type: "Application", value: "application" },
    { type: "Feedback", value: "feedback" },
];

const EditFlow = (props) => (
    <Edit {...props} title={<Title />}>
        <SimpleForm>
            <TextInput source="name" />
            <SelectInput label="Flow Type" source="flowType" choices={flowTypes} optionText="type" optionValue="value" validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export default EditFlow;
