import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";
import config from "./config";
import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.css';


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [{
        name: "Analytics",
        endpoint: "https://xpkwd9diig.execute-api.eu-west-1.amazonaws.com/Prod",
        service: "lambda",
        custom_header: async() => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` };
        }
      },
      {
        name: "backoffice",
        endpoint: "https://api.zmashsolutions.com/backoffice",
        service: "lambda",
        custom_header: async() => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` };
        }
      },
      {
        name: "backoffice-dev",
        endpoint: "https://api.zmashsolutions.com/backoffice-dev",
        service: "lambda",
        custom_header: async() => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}` };
        }
      }
    ],

  }
});
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
