import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, ReferenceManyField, Tab, TabbedShowLayout, Datagrid} from 'react-admin';
import AddProjectButton from './AddProjectButton';
import AddUserButton from './AddUserButton';
import Title from './Title';

const ViewClient = (props) => (
  <Show {...props} title={<Title />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <SimpleShowLayout>
              <TextField source="name" />
              <TextField source="organizationNumber" />
              <TextField label="Address - Street" source="address.street" />
              <TextField label="Address - Postal Code" source="address.postalCode" />
              <TextField label="Address - City" source="address.city" />
              <TextField source="industry" />
              <DateField label="Publication date" source="createdDate" />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Projects" path="projects">
          <ReferenceManyField
            addLabel={false}
            reference="clientProject"
            target="clientId"
          >
            <Datagrid rowClick="show">
              <TextField source="name" />
              <TextField label="Project type" source="type" />
              <DateField source="startDate" />
              <DateField source="endDate" />
            </Datagrid>

          </ReferenceManyField>
          <AddProjectButton />
        </Tab>
        <Tab label="Users" path="users">
          <ReferenceManyField
            addLabel={false}
            reference="user"
            target="clientId"
          >
            <Datagrid rowClick="show">
              <TextField label="Email" source="attr.email" />
              <TextField label="First Name" source="attr.name" />
              <TextField label="Family Name" source="attr.family_name" />
            </Datagrid>
          </ReferenceManyField>
          <AddUserButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export default ViewClient;
