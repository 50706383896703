import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, TextField, DateInput, 
  LongTextInput, BooleanInput, ArrayInput, SimpleFormIterator, ReferenceInput,
  FormDataConsumer
} from 'react-admin';
import Title from './Title';
import moment from 'moment-timezone';
import languageChoices from '../../shared-components/languageCodes';

const choices = [
  { type: "Career Solutions" },
  { type: "Recruitment Marketing" },
  { type: "Influencer Marketing" }
];

const statusChoices = [
  { status: "Not Started" },
  { status: "Live" },
  { status: "Paused" },
  { status: "Finished" }
];

const metricsComparisonChoices = [
  { type: "Applications", value: 'applications' },
  { type: "Leads", value: 'leads' },
];


const timezones = moment.tz.names().map(tz => { return { timezone: tz } });

const EditClientProject = props => {
  // console.log(moment.tz.names());
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm>
        <TextInput source="name" />
        <SelectInput label="Project type" source="type" choices={choices} optionText="type" optionValue="type"/>
        <SelectInput label="Project Status" source="projectStatus" choices={statusChoices} optionText="status" optionValue="status"/>
        <TextInput label="Project Number" source="projectNumber" />
        <TextInput label="Project Value" source="value" />
        <TextField label="Analytics Timezone" source="analytics.timeZone"/>
        <ArrayInput source="personalDataMessages">
          <SimpleFormIterator>
            <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
            <LongTextInput source="message"/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="flowKeys">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ scopedFormData, getSource, ...rest }) => {
                return(
                  <ReferenceInput
                    source={getSource("FlowVersionId")}
                    reference="flowVersion"
                    label="Flow Version">
                    <SelectInput optionText="name" />
                  </ReferenceInput>                
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <DateInput source="startDate" />
        <DateInput source="endDate" />
        <div>
          <h5 style={{'margin':'.5em 0 1em 0'}}>Dashboard settings</h5>
          <BooleanInput source="hideProject" label="Hide Project" defaultValue={false} />
          <BooleanInput source="disableAiMetrics" label="Disable AI Metrics" defaultValue={false} />
          <BooleanInput source="disableApplicationMetrics" defaultValue={false} />
          <BooleanInput source="enableBetaFeatures" defaultValue={false} />
          <SelectInput label="Metrics Comparison" source="metricsComparison" choices={metricsComparisonChoices} optionText="type" optionValue="value"/>
          <ArrayInput source="variableBlacklist" label="Custom field blacklist">
            <SimpleFormIterator>
              <LongTextInput source="variable"/>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="displayAsTable">
            <SimpleFormIterator>
              <LongTextInput source="variable" label="Display custom field data as table"/>
            </SimpleFormIterator>
          </ArrayInput>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default EditClientProject;
