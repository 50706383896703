import React from 'react';
import { Edit, TabbedForm, TextInput, ReferenceInput, ReferenceArrayInput, AutocompleteArrayInput, BooleanInput, FormDataConsumer, SelectInput, LongTextInput, FormTab, ArrayInput, SimpleFormIterator } from 'react-admin';
import Title from './Title';
import languageChoices from '../../shared-components/languageCodes';
const statusChoices = [
  { status: "Live" },
  { status: "Paused" },
  { status: "Archived" }
];


const EditJob = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  return (
    <Edit {...props} title={<Title />}>
        <TabbedForm >
          <FormTab label="summary">
      
            <TextInput source="title" />
            <ReferenceArrayInput label="Job categories" reference="jobCategory" source="jobCategories">
              <AutocompleteArrayInput optionText="name" optionValue="id" />
            </ReferenceArrayInput>
            <TextInput source="city" />
            <LongTextInput label="Link to job" source="link" />
            <BooleanInput source="useRegex" defaultValue={false} label="Use Regex for Linkmatching" />
            <BooleanInput source="disableApplications" defaultValue={false} />
            <BooleanInput source="openApplication" defaultValue={false} />
            <SelectInput source="status" choices={statusChoices} optionText="status" optionValue="status"/>

            <BooleanInput source="overrideTemplate" defaultValue={false} />
            <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => formData.overrideTemplate &&
              <ArrayInput source="overrideTemplates" label="Template Overrrides" {...rest}>
                <SimpleFormIterator>
                    <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
                    <ReferenceInput
                      source="flowVersionId"
                      reference="flowVersion"
                      label="Flow Version">
                      <SelectInput optionText="name" optionValue="FlowVersionId" />
                    </ReferenceInput >
                  </SimpleFormIterator>
              </ArrayInput>          
            }
            </FormDataConsumer>
          </FormTab>
          <FormTab label="Integration Variables">
            <ArrayInput source="integrationVariables" >
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ scopedFormData, getSource, ...rest }) => {
                    return(
                      <div>
                        <TextInput source={getSource("IntegrationId")} label="IntegrationId" fullWidth/>
                        <ArrayInput source={getSource("variables")} label="variables">
                          <SimpleFormIterator>
                            <TextInput label="Name" source="name"/>
                            <TextInput label="Value" source="value"/>
                          </SimpleFormIterator>
                        </ArrayInput>          
                      </div>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>          
          </FormTab>
        </TabbedForm>
    </Edit>
  );
};

export default EditJob;
