import React from 'react';
import { Show, SimpleShowLayout, TextField, Tab, TabbedShowLayout} from 'react-admin';
import Title from './Title';

const ViewJobCategory = (props) => (
  <Show {...props} title={<Title />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <SimpleShowLayout>
              <TextField source="name" />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export default ViewJobCategory;

        // <Tab label="Projects" path="projects">
        //   <ReferenceManyField
        //     addLabel={false}
        //     reference="clientProject"
        //     target="clientId"
        //   >
        //     <Datagrid rowClick="show">
        //       <TextField source="name" />
        //     </Datagrid>

        //   </ReferenceManyField>
        //   <AddProjectButton />
        // </Tab>