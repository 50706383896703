// import React from 'react';
import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import { change, reset, initialize } from "redux-form";
import WorkoFieldSelector from './integrations/worko/workoFieldSelector';
import WorkoSelectInput from './integrations/worko/workoSelectInput';

import {
  Edit,
  TextInput,
  TextField,
  SelectInput,
  DisabledInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  BooleanInput,
  SimpleShowLayout,
  required,
  ReferenceInput,
  REDUX_FORM_NAME
}
from 'react-admin';

import Title from './Title';
import uuid from 'uuid';
import { API } from 'aws-amplify';
import config from "../../config.json";
const API_URL = process.env.NODE_ENV === 'development' ? config.api.devUrl : config.api.url;
const apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiNameProd;


const choices = [
  { type: "true", label: "Yes" },
  { type: "false", label: "No" }
];

const botTypes = [
  { type: "Lead", value: "lead" },
  { type: "Application", value: "application" },
];

const redirect = (basePath, id, data) => `/clientProject/${data.ProjectId}/show/bots`;
const getFields = async (botId, integrationId) => {
  if (botId && integrationId) {

    let list = await API.get(apiName, `/integrationField?integrationId=${integrationId}&botId=${botId}`);
    return list;
  }
  else return { Count: 0, Items: [] };
};

class EditBot extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.state = { visible: false };
  }
  render() {
    return (
      <Edit {...this.props} title={<Title />} >
        <TabbedForm redirect={redirect} >
            <FormTab label="summary">
                <TextInput source="name" />
            </FormTab>
            <FormTab label="integrations" >
                <div>
                  <h5>BlackHole</h5>
                  <BooleanInput source="blackhole.enabled" label="Enable Black hole" defaultValue={false}/>
                  <BooleanInput source="blackhole.showProcess" label="Show Messages with application process" defaultValue={false}/>
                  <TextInput source="blackhole.clientName" label="Client name in emails" />
                  <TextInput source="blackhole.emailAddressPrefix" label="Email address Prefix" />
                  <TextInput source="blackhole.bgColorCode" label="Background Color Code" />
                </div>
                <ArrayInput source="integrations" fullWidth>
                    <SimpleFormIterator >
                      <FormDataConsumer>
                        {({ scopedFormData, getSource, ...rest }) => {
                            return (
                            <div>
                              <div className="syncSettings" style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0", width:"auto"}}>
                                <SelectInput source={getSource("type")} label="System" fullWidth choices={[
                                    { id: 'workable', name: 'Workable' },
                                    { id: 'ponty', name: 'Ponty' },
                                    { id: 'teamtailor', name: 'Teamtailor' },
                                    { id: 'sigma', name: 'Sigma' },
                                    { id: 'reachmee', name: 'ReachMee' },
                                    { id: 'talentrecruiter', name: 'Talent Recruiter' },
                                    { id: 'worko', name: 'Worko Dynamics' },
                                    { id: 'hubspot', name: 'Hubspot' },
                                    { id: 'hubspot-forms', name: 'Hubspot Forms' },
                                    { id: 'kombo', name: 'Kombo' },
                                    { id: 'recruto', name: 'Recruto' }
                                ]} />
                                {scopedFormData && scopedFormData.id && 
                                  <DisabledInput source={getSource("id")} defaultValue={() => uuid.v4()} label="Integration Id" fullWidth/>
                                }
                                <h4>BlackHole</h4>
                                <div style={{marginBottom:"1em", background:"#fafafa", borderRadius: "1em", padding:"1em"}}>
                                  <ArrayInput source={getSource('mapping.stages')} fullWidth label="Stage Mappings" >
                                    <SimpleFormIterator >
                                        <TextInput label="Ats Name" source="key"/>
                                        <TextInput label="New Name" source="mappedName"/>
                                    </SimpleFormIterator>
                                  </ArrayInput>
                                </div>
                              </div>
                              <div className="syncSettings" style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0", width:"auto"}}>
                                <h3>Synchronization Settings</h3>
                                <div style={{display:"flex"}}>
                                  <div style={{marginRight:"2em"}}>
                                    <h5>Applications</h5>
                                      <SelectInput source={getSource("synchronization.applications.status")} label="Status" fullWidth choices={[
                                        { id: 'sync', name: 'Sync' },
                                        { id: 'nosync', name: 'Do not Sync' }
                                      ]} />
                                      <SelectInput source={getSource("synchronization.applications.type")} label="Frequency" fullWidth choices={[
                                        { id: 'never', name: 'Never' },
                                        { id: 'continuous', name: 'Continuous' },
                                        // { id: 'daily', name: 'Daily' }
                                      ]} />
                                    </div>
                                    <div>
                                      <h5>Jobs</h5>
                                      <SelectInput source={getSource("synchronization.jobs.status")} label="Status" fullWidth choices={[
                                        { id: 'sync', name: 'Sync' },
                                        { id: 'nosync', name: 'Do not Sync' }
                                      ]} />
                                      <SelectInput source={getSource("synchronization.jobs.type")} label="Frequency" fullWidth choices={[
                                        { id: 'never', name: 'Never' },
                                        { id: 'continuous', name: 'Continuous' },
                                        // { id: 'daily', name: 'Daily' }
                                      ]} />
                                      <BooleanInput source={getSource("synchronization.jobs.pauseArchivedJobs")} label="Pause Archived Jobs" defaultValue={true}/>
                                      <BooleanInput source={getSource("synchronization.jobs.updateCreatedJobs")} label="Update Created Jobs" defaultValue={true}/>
                                    </div>
                                  </div>
                                </div>                     
                              </div>
                              );
                          }}
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData, scopedFormData, getSource, dispatch,  ...rest }) => {
                          if (scopedFormData && scopedFormData.type) {
                            if (scopedFormData.type === 'workable') {
                              return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Workable Settings</h3>
                                  <h4>Settings</h4>
                                  <BooleanInput source={getSource("synchronization.jobs.includeInternal")} label="Get internal jobs" />
                                  <BooleanInput source={getSource("synchronization.jobs.pauseInternal")} label="Set Internal jobs to 'Paused' status when updating" />
                                  <h4>Authorization</h4>
                                  <TextInput label="Subdomain" fullWidth source={getSource('auth.subdomain')} />
                                  <TextInput label="Partner Key" fullWidth source={getSource('auth.partnerKey')} />
                                  <h4>Filters</h4>
                                  <div style={{marginBottom:"1em", background:"#fafafa", borderRadius: "1em", padding:"1em"}}>
                                    <ArrayInput source={getSource('mapping.skillFilters')} fullWidth label="Skill Filters" >
                                      <SimpleFormIterator >
                                          <TextInput label=" " source="filter"/>
                                      </SimpleFormIterator>
                                    </ArrayInput>
                                  </div>
                                  <h4>Field Mapping</h4>
                                  <div style={{marginBottom:"1em", background:"#fafafa", borderRadius: "1em", padding:"1em"}}>
                                    <TextInput style={{display:'block'}} label="Workable - Headline" source={getSource('mapping.variables.headline')} placeholder="Enter Custom Field name"/>
                                    <TextInput style={{display:'block'}} label="Workable - Summary" source={getSource('mapping.variables.summary')} placeholder="Enter Custom Field name"/>
                                    <TextInput style={{display:'block'}} label="Workable - Address" source={getSource('mapping.variables.address')} placeholder="Enter Custom Field name"/>
                                    <TextInput style={{display:'block'}} label="Workable - Cover Letter" source={getSource('mapping.variables.cover_letter')} placeholder="Enter Custom Field name"/>
                                  </div>
                                  <div className="explist" style={{marginBottom:"1em", background:"#fafafa", borderRadius: "1em", padding:"1em"}}>
                                    <h5>Experience Entries</h5>
                                    <ArrayInput source={getSource('mapping.variables.experience_entries')} label=' '>
                                      <SimpleFormIterator>
                                          <TextInput label="Summary" source="summary"/>
                                          <TextInput label="Title" source="title"/>
                                          <TextInput label="Company" source="company"/>
                                          <TextInput label="Industry" source="industry"/>
                                      </SimpleFormIterator>
                                    </ArrayInput>                                    
                                    </div>
                                </div>
                                );
                              }
                              if (scopedFormData.type === 'ponty') {
                              return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Ponty Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="Client ID" fullWidth source={getSource('auth.clientId')} />
                                  <TextInput label="Client Secret" fullWidth source={getSource('auth.clientSecret')} />
                                </div>
                                );
                              }
                              if (scopedFormData.type === 'reachmee') {
                              return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>ReachMee Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="UserName (from Client/Reachmee)" fullWidth source={getSource('auth.username')} />
                                  <TextInput label="Password (from Client/Reachmee)" fullWidth source={getSource('auth.password')} />
                                  <TextInput label="Publishing Place ID (from Client/Reachmee)" fullWidth source={getSource('auth.publishingPlaceId')} />
                                  <TextInput label="Language Code" fullWidth source={getSource('auth.languageCode')} />
                                  <h4>Settings</h4>
                                  <BooleanInput label="Use Feed for Jobs" fullWidth source={getSource('feed.useFeed')} defaultValue={false} />
                                  {scopedFormData && scopedFormData.feed && scopedFormData.feed.useFeed &&
                                  <TextInput label="Feed URL (from Client/Reachmee)" fullWidth source={getSource('feed.url')} />
                                  }
                                  <TextInput label='Base URL (...reachmee.com/.../job?..., NO "&job_id="" PARAMETER)' fullWidth source={getSource('baseUrl')} />
                                </div>
                                );
                              }
                              if (scopedFormData.type === 'talentrecruiter') {
                              return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Talent Recruiter Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="Client ID" fullWidth source={getSource('auth.clientId')} />
                                  <TextInput label="Client Secret" fullWidth source={getSource('auth.clientSecret')} />
                                  <TextInput label="Alias" fullWidth source={getSource('auth.alias')} />
                                </div>
                                );
                              }
                              if (scopedFormData.type === 'sigma') {
                              return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Sigma Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="API Key" fullWidth source={getSource('auth.apiKey')} />
                                  <TextInput label="API Origin" fullWidth source={getSource('auth.apiOrigin')} />
                                  <TextInput label="Feed Url" fullWidth source={getSource('feed.url')} />
                                </div>
                                );
                              }
                              if (scopedFormData.type === 'recruto') {
                              return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Recruto Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="API Key" fullWidth source={getSource('auth.apiKey')} />
                                  <TextInput label='Feed Url (Add "/j/1" in the end if its missing)' fullWidth source={getSource('feed.url')} />
                                </div>
                                );
                              }
                              if (scopedFormData.type === 'teamtailor') {
                              let options = [];
                              const  scopedGetFields = async (botId, integrationId, source) =>{
                                console.log('scoped function');
                                let list = await getFields(botId, integrationId);
                                list.Items = list.Items.map(cf=>{
                                  return {id:cf.id, name: `(${cf.id}) ${cf.name} `}
                                });
                                options = list.Items;
                                
                              }
                              const getOptions = ()=>{
                                console.log('options');
                                return options;
                              }
                              
                              const TeamtailorInput = ({ name, label, dataProvider, source, botId, integrationId, formData, ...rest}) => {
                                // const [open, setOpen] = useState(false);
                                const [options, setOptions] = useState([]);
                                const [mandatoryOptions, setMandatoryOptions] = useState([]);
                                
                                // const loading = options.length === 0;
                                
                                
                                
                                useEffect(() => {
                                  (async () => {
                                      let list = []
                                      let selectOptions = options.length > 0 ? options:  window.options || null;
                                      if (selectOptions && selectOptions.length > 0) {
                                        setOptions(selectOptions);
                                        list = options
                                      }
                                      else {
                                        let listResp = await getFields(botId, integrationId)
                                        if (listResp.Items) {
                                          list = listResp.Items.map(cf=>{
                                          let obj = {id:cf.id, name: `(${cf.id}) ${cf.label}`};
                                          
                                          if(cf.options){
                                             obj.options = cf.options.map(o=> {
                                              return {id:o.id, name:o.name };
                                            })
                                          }
                                          return obj
                                              
                                            });
                                        }
                                        if (list && list.length > 0) {
                                            window.options = list;
                                            setOptions(list);
                                            setMandatoryOptions(list);
                                            // let mandatoryOptions = list.filter(o => o.options);
                                            let mandatoryOptions = list.filter(o => o.options);
                                            this.setState({mandatoryOptions:mandatoryOptions});
                                            // this.setState({options: list})
                                        }
                                      }
                                })()
                                }
                                )

                            
                                return (
                                  <SelectInput
                                      source={source}
                                      choices={options}
                                      optionText="name"
                                      optionValue="id"
                                      label={label}
                                  />
                                    // record[source]
                                      
                                );
                            };
                            
                              return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Teamtailor Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="API Key" fullWidth source={getSource('auth.apiKey')} />
                                  <BooleanInput label='Set applications as "Sourced"' fullWidth source={getSource('auth.setSourced')} defaultValue={false} />
                                  
                                  <h4>Field Mapping</h4>
                                  <div style={{marginBottom:"1em", background:"#fafafa", borderRadius: "1em", padding:"1em"}}>
                                    <SimpleShowLayout>
                                      <Button color="primary" onClick={()=>scopedGetFields(formData.BotId, scopedFormData.id, getSource('mapping.variables'))}>Get Fields</Button>
                                      {options.map(o=>{
                                        return <span>{o.id}</span>;
                                      })}
                                    </SimpleShowLayout>
                                     <ArrayInput source={getSource('mapping.variables')} fullWidth label="Teamtailor Fields" >
                                       <SimpleFormIterator>
                                            <TeamtailorInput botId={formData.BotId} integrationId={scopedFormData.id} source={"teamtailorField"} label="Teamtailor Field"/>
                                            <ArrayInput source={'zmashVariables'} fullWidth label="Custom Fields (Zmash)" >
                                              <SimpleFormIterator >
                                                <TextInput style={{display:'block'}} label="Custom Field (Zmash)" source={"variableName"} placeholder="Enter Custom Field name"/>
                                              </SimpleFormIterator>
                                            </ArrayInput>
                                            <FormDataConsumer>
                                              {({
                                                  formData, 
                                                  scopedFormData, 
                                                  getSource, 
                                                  ...rest
                                              }) => {
                                                  let mandatoryOptions = this.state && this.state.mandatoryOptions && this.state.mandatoryOptions.length > 0 ? this.state.mandatoryOptions : window.options;
                                                  let val =  mandatoryOptions &&  scopedFormData && scopedFormData.teamtailorField ? mandatoryOptions.find(mo =>{
                                                    return mo.id === scopedFormData.teamtailorField;
                                                  } ): null;
                                                  if(val){
                                                    mandatoryOptions= val.options;
                                                  }
                                                  return mandatoryOptions ? (
                                                  <ArrayInput source={getSource('answerMapping')} fullWidth label="Answer Mapping" >
                                                    <SimpleFormIterator >
                                                    
                                                      <TextInput style={{display:'block'}} label="Input" source={"input"} placeholder=""/>
                                                      <SelectInput style={{display:'block'}} label="Output" source={"output"} optionText="name" optionValue="id" choices={mandatoryOptions}/>
                                                    </SimpleFormIterator>
                                                  </ArrayInput>
                                                              ) : <ArrayInput source={getSource('answerMapping')} fullWidth label="Answer Mapping" >
                                                    <SimpleFormIterator >
                                                      <TextInput style={{display:'block'}} label="Input" source={"input"} placeholder=""/>
                                                      <TextInput style={{display:'block'}} label="Output" source={"output"} placeholder="Enter Custom Field name"/>
                                                    </SimpleFormIterator>
                                                  </ArrayInput>;
                                               }
                                              }
                                            </FormDataConsumer>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                  </div>
                                </div>
                                );
                              }
                              
                              
                              if (scopedFormData.type === 'worko') {
                              
                                return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Worko Dynamics Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="Code" fullWidth source={getSource('auth.code')} />
                                  <h4>Field Mapping</h4>
                                  <div style={{marginBottom:"1em", background:"#fafafa", borderRadius: "1em", padding:"1em"}}>
                                     <ArrayInput source={getSource('mapping.variables')} fullWidth label="Worko Fields" >
                                       <SimpleFormIterator>
                                            <WorkoFieldSelector source="workoField" label="Worko Field"/>
                                            <ArrayInput source={'zmashVariables'} fullWidth label="Custom Fields (Zmash)" >
                                              <SimpleFormIterator >
                                                <TextInput style={{display:'block'}} label="Custom Field (Zmash)" source={"variableName"} placeholder="Enter Custom Field name"/>
                                              </SimpleFormIterator>
                                            </ArrayInput>
                                            <FormDataConsumer>
                                              {({
                                                  formData, 
                                                  scopedFormData, 
                                                  getSource, 
                                                  ...rest
                                              }) => {
                                              console.log(scopedFormData)
                                                  return  (
                                                  <ArrayInput source={getSource('answerMapping')} fullWidth label="Answer Mapping" >
                                                    <SimpleFormIterator >
                                                      <TextInput style={{display:'block'}} label="Input" source={"input"} placeholder=""/>
                                                      {scopedFormData && scopedFormData.workoField && (scopedFormData.workoField !=='availableIn' && scopedFormData.workoField !== 'jobTitle') &&
                                                        (<WorkoSelectInput style={{display:'block'}} label="Output" source={"output"} optionText="name" optionValue="id" scopedFormData={scopedFormData}/>)
                                                      }
                                                      {scopedFormData && scopedFormData.workoField && (scopedFormData.workoField ==='availableIn' ||  scopedFormData.workoField === 'jobTitle') &&
                                                        <TextInput style={{display:'block'}} label="Output" source={"output"} placeholder=""/>
                                                      }
                                                    </SimpleFormIterator>
                                                  </ArrayInput>);
                                               }
                                              }
                                            </FormDataConsumer>

                                        </SimpleFormIterator>
                                    </ArrayInput>
                                  </div>
                                </div>
                                );
                              }
                              if (scopedFormData.type === 'hubspot') {
                              
                                return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Hubspot Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="Token" fullWidth source={getSource('auth.apiToken')} />
                                  <h4>Field Mapping</h4>
                                  <div style={{marginBottom:"1em", background:"#fafafa", borderRadius: "1em", padding:"1em"}}>
                                    <ArrayInput source={getSource('mapping.checkboxFields')}  fullWidth label="Checkbox Fields" >
                                      <SimpleFormIterator >
                                          <TextInput source="hubspotField" label="Hubspot Field"/>
                                          <BooleanInput source="checked" label="Set Checked" defaultValue={false}/>
                                      </SimpleFormIterator>
                                    </ArrayInput>
                                     <ArrayInput source={getSource('mapping.variables')} fullWidth label="Hubspot Fields" >
                                       <SimpleFormIterator>
                                            <TextInput source="hubspotField" label="Hubspot Field"/>
                                            <ArrayInput source={'zmashVariables'} fullWidth label="Custom Fields (Zmash)" >
                                              <SimpleFormIterator >
                                                <TextInput style={{display:'block'}} label="Custom Field (Zmash)" source={"variableName"} placeholder="Enter Custom Field name"/>
                                              </SimpleFormIterator>
                                            </ArrayInput>
                                            <FormDataConsumer>
                                              {({
                                                  formData, 
                                                  scopedFormData, 
                                                  getSource, 
                                                  ...rest
                                              }) => {
                                              console.log(scopedFormData)
                                                  return  (
                                                  <ArrayInput source={getSource('answerMapping')} fullWidth label="Answer Mapping" >
                                                    <SimpleFormIterator >
                                                      <TextInput style={{display:'block'}} label="Input" source={"input"} placeholder=""/>
                                                      <TextInput style={{display:'block'}} label="Output" source={"output"} placeholder=""/>
                                                    </SimpleFormIterator>
                                                  </ArrayInput>);
                                               }
                                              }
                                            </FormDataConsumer>

                                        </SimpleFormIterator>
                                    </ArrayInput>
                                  </div>
                                </div>
                                );
                              }
                              if (scopedFormData.type === 'hubspot-forms') {
                              
                                return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Hubspot Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="Token" fullWidth source={getSource('auth.apiToken')} />
                                  <TextInput label="Form Id" fullWidth source={getSource('auth.formId')} />
                                  <TextInput label="Portal Id" fullWidth source={getSource('auth.portalId')} />
                                  <h4>Field Mapping</h4>
                                  <div style={{marginBottom:"1em", background:"#fafafa", borderRadius: "1em", padding:"1em"}}>
                                    <ArrayInput source={getSource('mapping.checkboxFields')}  fullWidth label="Checkbox Fields" >
                                      <SimpleFormIterator >
                                          <TextInput source="hubspotField" label="Hubspot Field"/>
                                          <BooleanInput source="checked" label="Set Checked" defaultValue={false}/>
                                      </SimpleFormIterator>
                                    </ArrayInput>
                                     <ArrayInput source={getSource('mapping.variables')} fullWidth label="Hubspot Fields" >
                                       <SimpleFormIterator>
                                            <TextInput source="hubspotField" label="Hubspot Field"/>
                                            <ArrayInput source={'zmashVariables'} fullWidth label="Custom Fields (Zmash)" >
                                              <SimpleFormIterator >
                                                <TextInput style={{display:'block'}} label="Custom Field (Zmash)" source={"variableName"} placeholder="Enter Custom Field name"/>
                                              </SimpleFormIterator>
                                            </ArrayInput>
                                            <FormDataConsumer>
                                              {({
                                                  formData, 
                                                  scopedFormData, 
                                                  getSource, 
                                                  ...rest
                                              }) => {
                                              console.log(scopedFormData)
                                                  return  (
                                                  <ArrayInput source={getSource('answerMapping')} fullWidth label="Answer Mapping" >
                                                    <SimpleFormIterator >
                                                      <TextInput style={{display:'block'}} label="Input" source={"input"} placeholder=""/>
                                                      <TextInput style={{display:'block'}} label="Output" source={"output"} placeholder=""/>
                                                    </SimpleFormIterator>
                                                  </ArrayInput>);
                                               }
                                              }
                                            </FormDataConsumer>

                                        </SimpleFormIterator>
                                    </ArrayInput>
                                  </div>
                                </div>
                                );
                              }
                              
                              if (scopedFormData.type === 'kombo') {
                                return (
                                <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
                                  <h3>Hubspot Settings</h3>
                                  <h4>Authorization</h4>
                                  <TextInput label="API Key" fullWidth source={getSource('auth.apiKey')} />
                                  <TextInput label="Kombo Integration Id" fullWidth source={getSource('auth.integrationId')} />
                                  <BooleanInput label="Attach CV PDF" fullWidth source={getSource('makePdf')} defaultValue={false} />
                                  
                                  <h4>Field Mapping</h4>
                                  <div style={{marginBottom:"1em", background:"#fafafa", borderRadius: "1em", padding:"1em"}}>
                                     <ArrayInput source={getSource('mapping.variables')} fullWidth label="Hubspot Fields" >
                                       <SimpleFormIterator>
                                            <TextInput source="hubspotField" label="Hubspot Field"/>
                                            <ArrayInput source={'zmashVariables'} fullWidth label="Custom Fields (Zmash)" >
                                              <SimpleFormIterator >
                                                <TextInput style={{display:'block'}} label="Custom Field (Zmash)" source={"variableName"} placeholder="Enter Custom Field name"/>
                                              </SimpleFormIterator>
                                            </ArrayInput>
                                            <FormDataConsumer>
                                              {({
                                                  formData, 
                                                  scopedFormData, 
                                                  getSource, 
                                                  ...rest
                                              }) => {
                                              console.log(scopedFormData)
                                                  return  (
                                                  <ArrayInput source={getSource('answerMapping')} fullWidth label="Answer Mapping" >
                                                    <SimpleFormIterator >
                                                      <TextInput style={{display:'block'}} label="Input" source={"input"} placeholder=""/>
                                                      <TextInput style={{display:'block'}} label="Output" source={"output"} placeholder=""/>
                                                    </SimpleFormIterator>
                                                  </ArrayInput>);
                                               }
                                              }
                                            </FormDataConsumer>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                  </div>
                                </div>
                                );
                              }
                            }
                          }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
    );
  }
}

export default EditBot;
