import React from 'react';

class SaveLeadEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      action: this.props.action
    };
  }
  handleChange(event) {
    let { action } = this.state;
    action.content.messageContent = event.target.value;
    this.setState({ action: action });
  }
  handleVariableChange(event) {
    let { action } = this.state;
    action.content.variables[event.target.name].name = event.target.value;
    this.setState({ action: action });
  }
  addVariable(event) {
    let { action } = this.state;
    let variable = {
      name: 'email'
    };
    action.content.variables.push(variable);
    this.setState({ action: action });
  }
  removeVariable(event) {
    let { action } = this.state;
    let i = event.target.getAttribute('data-index');
    action.content.variables.splice(Number(i), 1);
    this.setState({ action: action });
  }
  getWithBr(message) {
    return message.replace(/(\r\n|\n|\r)/gm, "", "");
  }

  render() {
    let { action } = this.state;

    return (
      <div className="save-lead-contents">
       <div className="variables">
        <div className="label">
            Save Feedback
         </div>
          { /* action.content.variables && action.content.variables.length >0 &&
          (
          <div>
                {action.content.variables.map((variable1,i) => {
                   return (
                   <div key={i} className="variable-row">
                   <i className="material-icons" 
                        onClick={this.removeVariable.bind(this)} 
                        data-index={i}>cancel</i>
                     <select id="variable" name={i} onChange={this.handleVariableChange.bind(this)} value={variable1.name}>
                        {this.props.variables.map((variable, ind)=>{
                          return <option key={ind} value={variable} >{variable}</option>;
                        }
                      )}
                     </select>
                   </div>
                   );
                })}
           </div>
          )*/}
           </div>
        <i onClick={this.addVariable.bind(this)} className="material-icons">add_circle_outline</i>
     </div>
    );
  }
}
export default SaveLeadEditor;