// in src/App.js
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { AuthProvider, Login } from 'ra-cognito';
import { Route } from 'react-router-dom';
import adminApiProvider from './providers/admin-api';
import { ListClients, ViewClient, EditClient, CreateClient } from './modules/clients/Client.js';
import { ListClientProjects, ViewClientProject, EditClientProject, CreateClientProject } from './modules/clientProjects/ClientProject.js';
import { ListBots, ViewBot, EditBot, CreateBot } from './modules/bots/Bot.js';
import { ListBotImplementations, ViewBotImplementation, EditBotImplementation, CreateBotImplementation } from './modules/botImplementations/BotImplementation.js';
import { ListJobs, ViewJob, EditJob, CreateJob } from './modules/jobs/Job.js';
import { ListJobCategories, ViewJobCategory, EditJobCategory, CreateJobCategory } from './modules/jobCategories/JobCategory.js';
import { ListIntegrationJobs } from './modules/integrationJobs/IntegrationJob.js';
import { ListFlows, ViewFlow, EditFlow, CreateFlow } from './modules/flows/Flow.js';
import { ListFlowVersions, ViewFlowVersion, EditFlowVersion, CreateFlowVersion } from './modules/flowVersions/FlowVersion.js';
import { ListUsers, ViewUser, CreateUser } from './modules/users/User.js';
import { CreateActiveFlow } from './modules/activeFlows/ActiveFlow.js';
import { ListGdprRequests, ViewGdprRequest, EditGdprRequest } from './modules/gdprRequests/GdprRequest.js';
import FlowEditor from './modules/flow-editor/components/FlowEditor';
import { createBrowserHistory as createHistory } from 'history';
const history = createHistory();
const App = () => (
    <Admin
        authProvider={AuthProvider}
        history={history}
        dataProvider={adminApiProvider}
        customRoutes={[
            <Route
                exact path="/flow-editor"
                component={FlowEditor}
                noLayout
            />,
        ]}
        loginPage={Login}
        >
        <Resource name="client" create={CreateClient} show={ViewClient} edit={EditClient} list={ListClients} />
        <Resource name="clientProject" options={{ label: 'Projects' }} create={CreateClientProject} show={ViewClientProject} edit={EditClientProject} list={ListClientProjects} />
        <Resource name="bot" create={CreateBot} show={ViewBot} edit={EditBot} list={ListBots} />
        <Resource name="botImplementation" 
            create={CreateBotImplementation} 
            show={ViewBotImplementation} 
            edit={EditBotImplementation} 
            list={ListBotImplementations}
            options={{ label: 'Bot Implementations' }}
        />
        <Resource name="flow" create={CreateFlow} show={ViewFlow} edit={EditFlow} list={ListFlows} />
        <Resource name="flowVersion" options={{ label: 'Flow versions' }} create={CreateFlowVersion} show={ViewFlowVersion} edit={EditFlowVersion} list={ListFlowVersions} />
        <Resource name="job" create={CreateJob} show={ViewJob} edit={EditJob} list={ListJobs} />
        <Resource name="integrationJob" list={ListIntegrationJobs} />
        <Resource name="integrationField" />
        <Resource name="jobCategory" options={{ label: 'Job Categories' }} create={CreateJobCategory} show={ViewJobCategory} edit={EditJobCategory} list={ListJobCategories} />
        <Resource name="activeFlow" options={{ label: 'Published Flows' }} create={CreateActiveFlow} />
        <Resource name="user" create={CreateUser} show={ViewUser} list={ListUsers} />
        <Resource name="gdprRequest" options={{ label: 'Personal Data Requests' }}  show={ViewGdprRequest} edit={EditGdprRequest} list={ListGdprRequests} />
    </Admin>
);

export default App;