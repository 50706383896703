import React from 'react';

function SaveScreen(props) {
  return (
    <div className="save-screen-wrapper">
        <div className="save-screen-modal">
            {props.message}...
        </div>
    </div>
  );
}

export default SaveScreen;
