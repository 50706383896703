import React from 'react';
import { ShowController, ShowView, SimpleShowLayout, TextField, DateField, ArrayField, Tab, TabbedShowLayout, Datagrid } from 'react-admin';
import Title from './Title';

const ModificationField = ({ record = {} }) => {
  let val = '';

  switch (record.modification) {
    case 'deleteFieldValue':
      val = 'Delete Field';
      break;
    case 'editFieldValue':
      val = 'Edit field';
      break;
    case 'deleteCandidate':
      val = 'Delete candidate';
      break;
    case 'deleteLead':
      val = 'Delete lead';
      break;

    default:
      // code
  }
  return (<span>{val}</span>);
};

const ViewFlow = (props) => (
  <ShowController  {...props} title={<Title />}>
    {controllerProps =>
    <ShowView {...props} {...controllerProps}>
  
      <SimpleShowLayout>
        <TextField source="RequestId" label="Request Id" />
        <ArrayField source="request" label="Requested Changes">
          <Datagrid>
            <ModificationField source="modification" label="Modification Requested"/>
            <TextField source="field" label="Field"/>
            <TextField source="editFieldValue" label="New Value for field" />
          </Datagrid>
        </ArrayField>
        {controllerProps.record && controllerProps.record.currentStatus === 3 &&
          <TextField source="closingComment"/>
        }
        <DateField source="created" label="Request Sent" />
      </SimpleShowLayout>
    </ShowView>
    }
  </ShowController>
);

export default ViewFlow;
