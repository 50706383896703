import React from 'react';
import Highlighter from "react-highlight-words";

class MessageWithLink extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action
      };
   }
   render() {
      let { action } = this.props;
  
      return (
         <div className="action message">
            <Highlighter
             highlightClassName="variable-highlight"
             searchWords={[/\{\{\{.*\}\}\}/]}
            //  autoEscape={true}
             textToHighlight={action.content.messageContent}
           />
           <div className="link-cta">
           {action.content.messageLinkCta}
           </div>
         </div>
      );
   }
}
export default MessageWithLink;
