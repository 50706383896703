import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, BooleanInput } from 'react-admin';
import Title from './Title';

const choices = [
   { type: "JavaScript Client", value: "js"},
];
const redirect = (basePath, id, data) => `/bot/${data.BotId}/show/botImplementations`;

const EditBotImplementation = props => {
  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm redirect={redirect} >
          <TextInput source="name" />
          <BooleanInput label="Start with NLP" source="nlpStart" />
          <SelectInput label="Implementation Type" source="type" choices={choices} optionText="type" optionValue="value"/>
        </SimpleForm>
    </Edit>
  );
};

export default EditBotImplementation;
