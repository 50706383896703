import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
    button: {
        marginTop: '1em'
    }
};

const AddBotButton = ({ classes, record }) => (
    <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={`/bot/create?projectId=${record.id}`}
    label="Add a Bot"
  >
  </Button>
);

export default withStyles(styles)(AddBotButton);
