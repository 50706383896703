import React from 'react';

class Message extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action
      };
   }
   handleChange(event){
      let {action} = this.state;
      action.content.messageContent = event.target.value;
      this.setState({action: action});
   }
   getWithBr(message){
      return message.replace(/(\r\n|\n|\r)/gm,"", "");
   }
   render() {
      let { action } = this.state;

      return (
         <div className="message-content">
            <textarea name="messageContent" 
            value={action.content.messageContent}
            onChange={this.handleChange.bind(this)}></textarea>
            <div className="hidden-textarea">{action.content.messageContent.split('\n').map((item, key) => {
                 return <span key={key}>{item}<br/></span>;
               })}
            </div>
         </div>
      );
   }
}
export default Message;
