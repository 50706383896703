import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import Title from './Title';

const EditClient = (props) => (
    <Edit {...props} title={<Title />}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="organizationNumber" />
            <TextInput label="Address - Street" source="address.street" />
            <TextInput label="Address - Postal Code" source="address.postalCode" />
            <TextInput label="Address - City" source="address.city" />
            <TextInput source="industry" />
        </SimpleForm>
    </Edit>
);

export default EditClient;
