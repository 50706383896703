import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, BooleanInput, FormDataConsumer, LongTextInput, ArrayInput, SimpleFormIterator, ReferenceInput, NumberInput } from 'react-admin';
import Title from './Title';
import languageChoices from '../../shared-components/languageCodes';

const choices = [
  { type: "JavaScript Client", value: "js" },
  { type: "Facebook Messenger Bot", value: "fb" },
];

const quickReplyTypes = [
  { type: "Trigger Blackhole", value: "blackHole" },
];
const redirect = (basePath, id, data) => `/bot/${data.BotId}/show/botImplementations`;

const EditBotImplementation = props => {
  return <Edit {...props} title={<Title />} fullWidth >
      <SimpleForm
          redirect={ redirect }
          fullWidth 
      >
        <div>
          <FormDataConsumer fullWidth >
          {({ formData, ...rest }) => {
            return (
            <div>
              {formData && formData.bot && formData.bot.type !== 'lead' && 
              <div>
                {ApplicationBotImplementationForm(formData)}
              </div>
              }
              {formData && formData.bot && formData.bot.type === 'lead' && 
                <div>
                  {LeadBotImplementationForm(formData)}
                </div>
              }
              </div>
            );
          }}
          </FormDataConsumer>
        </div>
      </SimpleForm>
    </Edit>;
};

export default EditBotImplementation;

const ApplicationBotImplementationForm = (formData, redirect, props) => {
  return (
    <div>
    <h5 style={{'margin':'.5em 0 1em 0'}} >Basic properties</h5>

    <TextInput source="name" />
    <SelectInput label="Implementation Type" source="type" choices={choices} optionText="type" optionValue="value"/>
    <ArrayInput source="templateFlows" label="Template Flow">
      <SimpleFormIterator>
          <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
          <ReferenceInput
            source="flowVersionId"
            reference="flowVersion"
            label="Flow Version">
            <SelectInput optionText="name" optionValue="FlowVersionId" />
          </ReferenceInput >
        </SimpleFormIterator>
    </ArrayInput>
    
    {formData.type === 'fb' &&
      // we need a part for settting up and getting json starters for ad bots
      <div>
        <TextInput source="fbPageId" label= "Facebook Page Id" /><br />
        <BooleanInput label="Use NLP" source="useNlp"defaultValue={false} /><br />
        <TextInput source="fbPageAccessToken" label= "Facebook Page AccessToken" /><br />
        <h5>Facebook Messenger Profile</h5>
        
        <h6>Get started button</h6>
        <ReferenceInput
          source="messengerProfile.getStartedFlow"
          reference="flowVersion"
          label="Flow"
          allowEmpty
          >
          <SelectInput optionText="name" />
        </ReferenceInput>
        
        <ArrayInput source="messengerProfile.greetings"  {...props} label="Greetings">
            <SimpleFormIterator>
              <LongTextInput source="locale" defaultValue="default"/>
              <LongTextInput source="text"/>
            </SimpleFormIterator>
        </ArrayInput>
        <h6>Ice Breakers</h6>
        <ArrayInput source="messengerProfile.iceBreakers"  {...props} label="Ice breakers">
            <SimpleFormIterator>
              <LongTextInput source="question"/>
              <ReferenceInput
                source="flow"
                reference="flowVersion"
                label="Flow">
                <SelectInput optionText="name" 
              />
              </ReferenceInput>
            </SimpleFormIterator>
        </ArrayInput>
      </div>
    }
    
    {formData.type === 'js'  && 
      <div>
        <TextInput source="baseUrl" /><br />
        <BooleanInput label="Match URLs with Query String" source="matchQueryString" defaultValue={false} /><br />
        <BooleanInput label="iFrame Links For Jobs" source="iframeLinksForJobs" defaultValue={false} />
        <BooleanInput label="Perform DataLayer pushes to GTM" source="pushToDataLayer.shouldPush" defaultValue={false} />
        {formData && formData.pushToDataLayer && formData.pushToDataLayer.shouldPush &&
          <BooleanInput label="Hashed Email as Data Layer Variable" source="pushToDataLayer.hashedEmail" defaultValue={false} />
        }
      <BooleanInput label="Use NLP" source="useNlp" defaultValue={false}/><br />
      </div>
    }
    
    {formData && formData.type && formData.type === 'js' &&
        <div>
          <h5 style={{'margin':'.5em 0 1em 0'}}>Look & feel</h5>
          <TextInput source="headerImageUrl" />
          <TextInput source="avatarImageUrl" fullWidth />
          <TextInput source="colorcode" label="Primary colorcode" />
          <TextInput source="ctaBg" label="CTA Bubble - Background color code" />
          <TextInput source="ctaCc" label="CTA Bubble - Text color code" />
          <BooleanInput source="showPoweredByBanner" defaultValue={true} label="Show 'Powered by' banner"/>
          <BooleanInput source="autoOpen.desktop" defaultValue={false} label="Auto open - Desktop"/>
          <BooleanInput source="autoOpen.mobile" defaultValue={false} label="Auto open - Mobile"/>
          <BooleanInput source="autoOpen.sessionCookie" defaultValue={false} label="Don't Auto Open again in the same session"/>
          <BooleanInput source="animate" defaultValue={false} />
          <NumberInput source="zIndex" defaultValue={999999999} />
        </div>
    }
    {formData && formData.type && formData.type === 'js' &&
        <div>
          <h5 style={{'margin':'.5em 0 1em 0'}}>Copy</h5>
          <TextInput source="callToAction" />
          <TextInput source="jobCtaText" label="Text for Apply button" />
          <TextInput source="jobReadMoreCtaText" label="Text for Read More button" />
        </div>

        }
    
    

    {formData.useNlp &&
    <div>
      <h5 style={{'margin':'.5em 0 1em 0'}}>NLP settings</h5>
        <div>
          <BooleanInput label="Disable Applications from NLP JobList" source="disableNlpApplications" defaultValue={false} />
          <BooleanInput label="Enable Black Hole" source="blackHoleEnabled" defaultValue={false} />
        </div>
    </div>
    }
        
    <h5 style={{'margin':'.5em 0 1em 0'}}>Language settings</h5>
    <div>
      <ArrayInput source="languages"  {...props} choices={choices} optionText="type" optionValue="value" label="Languages" 
        defaultValue={[{languageCode: 'sv',nlpStart:false,welcomeBackEnabled:false}]}>
          <SimpleFormIterator>
            <FormDataConsumer>

            {({ getSource, scopedFormData }) => {
              return(
              <div style={{'border':"2px solid #aaa", 'borderRadius':'.5em','margin':'1em', 'padding':'1em'}}>
                <SelectInput label="Language" source={getSource("languageCode")} choices={languageChoices} optionText="lang" optionValue="value" />
                {formData.useNlp &&
                  <div>
                  <BooleanInput label="Use greetings from Dialogflow" source={getSource("nlpStart")} defaultValue={true} />
  
                  {formData && formData.type && formData.type === 'js' &&
                    <div>
                      <BooleanInput source={getSource("welcomeBackEnabled")} defaultValue={false} label="Use welcome-back messages"/>
                      <BooleanInput source={getSource("introStartEnabled")} defaultValue={false} label="Use Intro message before NLP" />
                      <TextInput source={getSource("jobCtaText")} label="Text for Apply button" />
                      <TextInput source={getSource("jobReadMoreCtaText")} label="Text for Read More button" />
                      <ArrayInput source={getSource("quickReplies")}  {...props} choices={choices} optionText="type" optionValue="value" label="Quick Replies">
                          <SimpleFormIterator>
                            <LongTextInput source="reply" defaultValue="Ja tack!" label="Reply"/>
                            <SelectInput source="trigger" defaultValue={null} choices={quickReplyTypes} optionText="type" allowEmpty optionValue="value" label="Trigger"/>
                          </SimpleFormIterator>
                      </ArrayInput>
                    </div>
                  }
                  {(!scopedFormData.nlpStart  ||scopedFormData.welcomeBackEnabled ) &&
                    <h6 style={{'margin':'.5em 0 1em 0'}}>Phrases</h6>
                  }
                  {!scopedFormData.nlpStart  &&
                    <div>
                      <ArrayInput source={getSource("phrases.greetings")} {...props} label="Greetings" >
                          <SimpleFormIterator>
                            <LongTextInput source="message"/>
                          </SimpleFormIterator>
                      </ArrayInput>
                    </div>
                  }
                  {scopedFormData.welcomeBackEnabled && 
                    <div>
                      <ArrayInput label="Welcome-back messages" source={getSource("phrases.welcomeBackMessages")} {...props} >
                          <SimpleFormIterator>
                            <LongTextInput source="message"/>
                          </SimpleFormIterator>
                      </ArrayInput>
                    </div>
                  }
                  {scopedFormData && scopedFormData.introStartEnabled && formData.useNlp &&
                    <div>
                      <h6 style={{'margin':'.5em 0 1em 0'}}>Intro settings</h6>
                      <LongTextInput source={getSource("intro.messageContent")} label="Intro message"/>
                      <BooleanInput source={getSource("intro.overrideNlp")} defaultValue={false} label="Override NLP Welcome Message" />
                      {scopedFormData && scopedFormData.intro && scopedFormData.intro.overrideNlp && formData.useNlp && 
                        <LongTextInput source={getSource("intro.overrideNlpMessage")} label="NLP Welcome Message"/>
                      }
                      <LongTextInput source={getSource("intro.ctaNlp")} label="CTA for NLP"/>
                      <LongTextInput source={getSource("intro.ctaFlow")} label="CTA for job flow" />
                      <ReferenceInput
                        source={getSource("intro.job")} 
                        reference="job"
                        filter={{id:formData.BotId}}
                        label="Job"
                      >
                        <SelectInput optionText="title" optionValue="JobId" />
                      </ReferenceInput>
                    </div>
                  }
                </div>
              }
              </div>
              );
            }}
            </FormDataConsumer>
          </SimpleFormIterator>
      </ArrayInput>
      
      
    </div>
    </div>);
};



const LeadBotImplementationForm = (formData, redirect, props) => {
  return <div>
    <h5 style={{'margin':'.5em 0 1em 0'}} >Basic properties</h5>

    <TextInput source="name" />
    <SelectInput label="Implementation Type" source="type" choices={choices} optionText="type" optionValue="value"/>
    
    
    {formData.type === 'fb' &&
      <div>
        <TextInput source="fbPageId" label= "Facebook Page Id" /><br />
        <BooleanInput label="Use NLP" source="useNlp" defaultValue={true} /><br />
        <TextInput source="fbPageAccessToken" label="Facebook Page AccessToken" /><br />
        <h5>Facebook Messenger Profile</h5>
        <h6>Get started button</h6>
        <ReferenceInput
          source="messengerProfile.getStartedFlow"
          reference="flowVersion"
          label="Flow"
          allowEmpty
          >
          <SelectInput optionText="name" />
        </ReferenceInput>
        
        <ArrayInput source="messengerProfile.greetings"  {...props} label="Greetings">
            <SimpleFormIterator>
              <LongTextInput source="locale" defaultValue="default"/>
              <LongTextInput source="text"/>
            </SimpleFormIterator>
        </ArrayInput>
        <h6>Ice Breakers</h6>
        <ArrayInput source="messengerProfile.iceBreakers"  {...props} label="Ice breakers">
            <SimpleFormIterator>
              <LongTextInput source="question"/>
              <ReferenceInput
                source="flow"
                reference="flowVersion"
                label="Flow"
                >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </SimpleFormIterator>
        </ArrayInput>
      </div>
    }
    
    {formData.type === 'js'  && 
      <div>
        <TextInput source="baseUrl" /><br />

        
        <BooleanInput label="Perform DataLayer pushes to GTM" source="pushToDataLayer.shouldPush" defaultValue={false} />
        {formData && formData.pushToDataLayer && formData.pushToDataLayer.shouldPush &&
          <BooleanInput label="Hashed Email as Data Layer Variable" source="pushToDataLayer.hashedEmail" defaultValue={false} />
        }
      </div>
    }
    
    {formData && formData.type && formData.type === 'js' &&
        <div>
          <h5 style={{'margin':'.5em 0 1em 0'}}>Look & feel</h5>
          <TextInput source="headerImageUrl" />
          <TextInput source="avatarImageUrl" />
          <TextInput source="colorcode" label="Primary colorcode" />
          <TextInput source="ctaBg" label="CTA Bubble - Background color code" />
          <TextInput source="ctaCc" label="CTA Bubble - Text color code" />
          <BooleanInput source="showPoweredByBanner" defaultValue={false} label="Show 'Powered by' banner"/>
          <BooleanInput source="autoOpen.desktop" defaultValue={false} label="Auto open - Desktop"/>
          <BooleanInput source="autoOpen.mobile" defaultValue={false} label="Auto open - Mobile"/>
          <BooleanInput source="autoOpen.sessionCookie" defaultValue={false} label="Don't Auto Open again in the same session"/>
          <BooleanInput source="animate" defaultValue={false} />
          <NumberInput source="zIndex" defaultValue={999999999} />
        </div>
      }
      {formData && formData.type && formData.type === 'js' &&
        <div>
          <h5 style={{'margin':'.5em 0 1em 0'}}>Copy</h5>
          <TextInput source="callToAction" />
        </div>
      }
      
    <h5 style={{'margin':'.5em 0 1em 0'}}>Language settings</h5>
    <ArrayInput source="templateFlows" label="Template Flow">
      <SimpleFormIterator>
          <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
          <ReferenceInput
            source="flowVersionId"
            reference="flowVersion"
            label="Flow Version">
            <SelectInput optionText="name" optionValue="FlowVersionId" />
          </ReferenceInput >
        </SimpleFormIterator>
    </ArrayInput>
    <div>
      <ArrayInput source="languages"  {...props} choices={choices} optionText="type" optionValue="value" label="Languages" 
        defaultValue={[{languageCode: 'sv',nlpStart:false,welcomeBackEnabled:false}]}>
          <SimpleFormIterator>
            <FormDataConsumer>

            {({ getSource, scopedFormData }) => {
              return(
              <div style={{'border':"2px solid #aaa", 'border-radius':'.5em','margin':'1em', 'padding':'1em'}}>
                <SelectInput label="Language" source={getSource("languageCode")} choices={languageChoices} optionText="lang" optionValue="value" />
                {formData.useNlp &&
                  <div>
                  <BooleanInput label="Use greetings from Dialogflow" source={getSource("nlpStart")} defaultValue={true} />
  
                  {formData && formData.type && formData.type === 'js' &&
                    <div>
                      <BooleanInput source={getSource("welcomeBackEnabled")} defaultValue={false} label="Use welcome-back messages"/>
                      <BooleanInput source={getSource("introStartEnabled")} defaultValue={false} label="Use Intro message before NLP" />
                      <TextInput source={getSource("jobCtaText")} label="Text for Apply button" />
                      <TextInput source={getSource("jobReadMoreCtaText")} label="Text for Read More button" />
                      <ArrayInput source={getSource("quickReplies")}  {...props} choices={choices} optionText="type" optionValue="value" label="Quick Replies">
                          <SimpleFormIterator>
                            <LongTextInput source="reply" defaultValue="Ja tack!" label="Reply"/>
                            <SelectInput source="trigger" defaultValue={null} choices={quickReplyTypes} optionText="type" allowEmpty optionValue="value" label="Trigger"/>
                          </SimpleFormIterator>
                      </ArrayInput>
                    </div>
                  }
                  {!scopedFormData.nlpStart  &&
                    <div>
                      <h6 style={{'margin':'.5em 0 1em 0'}}>Phrases</h6>
                      <ArrayInput source={getSource("phrases.greetings")} {...props} label="Greetings" >
                          <SimpleFormIterator>
                            <LongTextInput source="message"/>
                          </SimpleFormIterator>
                      </ArrayInput>
                    </div>
                  }
                  {scopedFormData.welcomeBackEnabled && 
                    <div>
                      <ArrayInput label="Welcome-back messages" source={getSource("phrases.welcomeBackMessages")} {...props} >
                          <SimpleFormIterator>
                            <LongTextInput source="message"/>
                          </SimpleFormIterator>
                      </ArrayInput>
                    </div>
                  }
                  {scopedFormData.introStartEnabled &&
                    <div>
                      <h6 style={{'margin':'.5em 0 1em 0'}}>Intro settings</h6>
                      <LongTextInput source={getSource("intro.messageContent")} label="Intro message"/>
                      <LongTextInput source={getSource("intro.ctaNlp")} label="CTA for NLP"/>
                      <LongTextInput source={getSource("intro.ctaFlow")} label="CTA for job flow" />
                      <ReferenceInput
                        source={getSource("intro.job")} 
                        reference="job"
                        filter={{id:formData.BotId}}
                        label="Job"
                      >
                        <SelectInput optionText="title" optionValue="JobId" />
                      </ReferenceInput>
                    </div>
                  }
                </div>
              }
              </div>
              );
            }}
            </FormDataConsumer>
          </SimpleFormIterator>
      </ArrayInput>
      
      
    </div>
  </div>;
};
