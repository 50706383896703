import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  SelectField,
  ReferenceManyField,
  Tab,
  TabbedShowLayout,
  Datagrid
}
from 'react-admin';
import AddVersionButton from './AddVersionButton';
import Title from './Title';
import languageChoices from '../../shared-components/languageCodes';

const flowTypes = [
  { type: "Lead", value: "lead" },
  { type: "Application", value: "application" },
];
const ViewFlow = (props) => (
  <Show {...props} title={<Title />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <SimpleShowLayout>
              <TextField source="name" />
              <SelectField label="Flow Type" source="flowType" choices={flowTypes} optionText="type" optionValue="value" />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Flow Versions" path="versions">
          <ReferenceManyField
            addLabel={false}
            reference="flowVersion"
            target="flowId"
          >
            <Datagrid rowClick="show">
              <TextField source="name" />
              <SelectField label="Language" source="languageCode" 
                choices={languageChoices} optionText="lang" 
                optionValue="value" 
              />
            </Datagrid>

          </ReferenceManyField>
          <AddVersionButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export default ViewFlow;
