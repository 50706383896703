import React from 'react';

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: this.props.action
    };
  }

  handleChange(event) {
    let { action } = this.state;
    action.content.messageContent = event.target.value;
    this.setState({ action: action });
  }

  handleVariableChange(event) {
    let { action } = this.state;
    action.content.variable = event.target.value;
    this.setState({ action: action });
  }

  handleQuickReplyChange(event) {
    let { action } = this.state;
    action.content.quickReplies[event.target.name].reply = event.target.value;
    this.setState({ action: action });
  }
  addQuickReply(event) {
    let { action } = this.state;
    let reply = {
      reply:"Quick Reply"
    };
    action.content.quickReplies = action.content.quickReplies || []
    action.content.quickReplies.push(reply);
    this.setState({ action: action });
  }
  removeQuickReply(event) {
    let { action } = this.state;
    let i = event.target.getAttribute('data-index');
    action.content.quickReplies.splice(Number(i), 1);
    this.setState({ action: action });
  }
  
  getWithBr(message) {
    return message.replace(/(\r\n|\n|\r)/gm, "", "");
  }

  render() {
    let { action } = this.state;

    return (
      <div className="question-contents">
        <div className="question-variable">
         <select id="variable" onChange={this.handleVariableChange.bind(this)} value={this.state.action.content.variable}>
          {this.props.variables.map((variable, i)=>{
            return <option key={i} value={variable}>{variable}</option>;
          }
          )}
         </select>
        </div>
        <div className="message-content">
           <textarea name="messageContent" 
           value={action.content.messageContent}
           onChange={this.handleChange.bind(this)}></textarea>
           <div className="hidden-textarea">
              {action.content.messageContent.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>;
              })}
           </div>
        </div>
        { action.content.answers && action.content.answers.length >0 && (
           <div className="answers">
              {action.content.answers.map((answer,i) => {
                 return (
                 <div key={i} className="answer-row">
                    <span className="answer">{answer.answerContent}</span>
                 </div>
                 );
              })}
           </div>
        )}
        { action.content.quickReplies && action.content.quickReplies.length >0 && (
           <div className="quick-replies answers">
            <span>Quick Replies</span>
              {action.content.quickReplies.map((quickReply,i) => {
                 return (
                 <div key={i} className="answer-row">
                    <i className="material-icons" 
                      onClick={this.removeQuickReply.bind(this)} 
                      data-index={i}>cancel</i>
                    <input className="answer" 
                      name={i} 
                      onChange={this.handleQuickReplyChange.bind(this)} 
                      value={quickReply.reply} />
                 </div>
                 );
              })}
           </div>
        )}
        { ((!action.content.quickReplies) || (action.content.quickReplies && action.content.quickReplies.length <5)) &&
          <i onClick={this.addQuickReply.bind(this)} className="material-icons">add_circle_outline</i>
        }
     </div>
    );
  }
}
export default Question;
