import React from 'react';

class GoToNode extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action
      };
   }
   handleChange(event){
      // let {action} = this.state;
      // action.content.messageContent = event.target.value;
      // this.setState({action: action});
   }
   render() {
      return (
         <div className="goToNode">
            Go To Node
         </div>
      );
   }
}
export default GoToNode;
