import React from 'react';
import { List, Datagrid, ReferenceField, TextField, CardActions, RefreshButton } from 'react-admin';

const ClientField = ({ record, ...rest }) =>
  record.attr['custom:client_id'] !== 'admin' ? (
    <ReferenceField label="Client" source="attr.custom:client_id" reference="client" linkType={false} record={record} {...rest} >
      <TextField source="name" />
    </ReferenceField>
  ) :
    <TextField source="attr.custom:client_id" record={record} {...rest} />;
const UsersActions = props => (
    <CardActions>
        <RefreshButton />
    </CardActions>
);


export const ListUsers = props => (
  <List {...props} 
    // actions={<UsersActions />}
  >
    <Datagrid rowClick="show">
      <ClientField label="Client"/>
      <TextField source="attr.email" />
    </Datagrid>
  </List>
);
export default ListUsers;
