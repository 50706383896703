import React from 'react';
import { Create , SimpleForm, TextInput } from 'react-admin';
import Title from './Title';

const CreateJobCategory = (props) => (
    <Create  {...props}  title={<Title />}>
        <SimpleForm redirect="list">
            <TextInput source="name" />
        </SimpleForm>
    </Create >
);

export default CreateJobCategory;
