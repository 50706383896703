import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const ListClients = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="industry" />
            <TextField label= "City" source="address.city" />
            <TextField source="organizationNumber" />
        </Datagrid>
    </List>
);
export default ListClients;
