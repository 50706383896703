import React from 'react';
import { List, Datagrid, TextField, SelectField, ReferenceField, DateField } from 'react-admin';


const choices = [
  { statusCode: 1, statusLabel: "Skickad" },
  { statusCode: 2, statusLabel: "Bearbetas" },
  { statusCode: 3, statusLabel: "Avslutad" },
];
export const ListFlows = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <ReferenceField label="Project" reference="clientProject" source="ProjectId" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="RequestId" />
            <SelectField source="currentStatus" choices={choices} optionValue="statusCode" optionText="statusLabel"  />
            <DateField label="Created" source="created" />
          </Datagrid>
    </List> 
);
export default ListFlows;
