import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, BooleanField, Tab, TabbedShowLayout } from 'react-admin';
import Title from './Title';

const ViewBotImplementation = (props) => (
  <Show {...props} title={<Title />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <SimpleShowLayout>
            <TextField source="name" />
            <BooleanField label="Start with NLP" source="nlpStart" />
            <TextField label="Bot type" source="type" />
            <DateField label="Created" source="createdDate" />
            <TextField label="Template flow" source="templateFlowId" />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export default ViewBotImplementation;