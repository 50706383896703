import React from 'react';
import { TextField,Labeled } from 'react-admin';

const NameField = ({ record, ...rest }) =>
    record.attr.name ? (
        <Labeled label="First Name">
            <TextField source="attr.name" record={record} {...rest} />
        </Labeled>
        ) : null;

export default NameField;
