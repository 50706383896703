import React from 'react';
import { Show, SimpleShowLayout, TextField, CardActions, Tab, TabbedShowLayout, DeleteButton} from 'react-admin';
import Title from './Title';
import NameField from './fields/NameField';
import FamilyNameField from './fields/FamilyNameField';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
const UserShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
       <DeleteButton basePath={basePath} record={data} resource={resource} />
    </CardActions>
);

const ViewUser = (props) => (
  <Show {...props} actions={<UserShowActions />} title={<Title />}>
  
      <TabbedShowLayout>
        <Tab label="Summary">
          <SimpleShowLayout>
            <TextField label="Email" source="attr.email"  />
            <NameField />
            <FamilyNameField />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export default ViewUser;
