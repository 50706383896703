import React from 'react';

class PersonalDataConsentEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: this.props.action
    };
  }
  handleAnswerChange(event) {
    let { action } = this.state;
    action.content.answers[event.target.name].answerContent = event.target.value;
    this.setState({ action: action });
  }

  render() {
    let { action } = this.state;

    return (
      <div className="question-contents">
        <div className="message-content">
           GDPR Consent
        </div>
        { action.content.answers && action.content.answers.length >0 && (
           <div className="answers">
              {action.content.answers.map((answer,i) => {
                 return (
                 <div key={i} className="answer-row">
                    <input className="answer" 
                      name={i} 
                      onChange={this.handleAnswerChange.bind(this)} 
                      value={answer.answerContent} />
                 </div>
                 );
              })}
           </div>
        )}
     </div>
    );
  }
}
export default PersonalDataConsentEditor;
