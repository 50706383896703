import React from 'react';
import { List, Datagrid, ReferenceField, TextField, BooleanField,SelectField, CardActions, RefreshButton } from 'react-admin';

const BotActions = props => (
  <CardActions>
        <RefreshButton />
    </CardActions>
);
const botTypes = [
  { type: "Lead", value: "lead" },
  { type: "Application", value: "application" },
];
export const ListBots = props => (
  <List {...props} actions={<BotActions />} >
    <Datagrid rowClick="show">
      <ReferenceField label="Client" source="ProjectId" reference="clientProject" linkType={false}>
          <TextField source="client.name" />
      </ReferenceField>
      <TextField source="name" />
                <SelectField label="Bot type" source="type" 
                  choices={botTypes} optionText="type" 
                  optionValue="value" 
                />    </Datagrid>
  </List>
);
export default ListBots;
