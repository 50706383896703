import React from 'react';
import { List,
          Datagrid,
          TextField,
          CardActions,
          RefreshButton,
          ReferenceField,
          ReferenceFieldController,
          BooleanField,
          ReferenceArrayField,
          SingleFieldList,
          ChipField
        } from 'react-admin';

const Title = ({ record }) => {
  return <span>Jobs</span>;
};

const JobProjectsActions = props => (
  <CardActions>
    
        <RefreshButton />
    </CardActions>
);
// <TextField source="ProjectId" />
export const ListJobProjects = props => (
  <List {...props} actions={<JobProjectsActions />} title={<Title />} >
        <Datagrid rowClick="show" >
            <ReferenceFieldController label="Client" reference="bot" source="BotId" linkType={false}>
            {({referenceRecord, ...props}) => (
                <ReferenceField basePath="/bot"  label="Client" source="ProjectId" reference="clientProject" linkType={false} record={referenceRecord || {}} >
                    <TextField source="client.name" />
                </ReferenceField>
            )}
            </ReferenceFieldController>
            <ReferenceArrayField label="Categories" reference="jobCategory" source="jobCategories">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="title" />
            <TextField source="city" />
            <TextField source="status" />
            <TextField source="IntegrationJobId" />
            <BooleanField source="openApplication" />
            <BooleanField source="overrideTemplate" />
            <BooleanField source="disableApplications" />
        </Datagrid>
    </List>
);
export default ListJobProjects;


//           <ReferenceField label="Client" source="ProjectId" reference="clientProject" linkType={false}>
//     <TextField source="client.name" />
// </ReferenceField>
