import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const style = { border: '2px solid #aaa', width: '70em', height: '15em', fontSize: '10px' };

class PreviewLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      previewUrl: '',
      biid: props.record ? props.record.biid : ''
    };
    this.handleChange = this.handleChange.bind(this);

  }
  handleChange(event) {

    const url = event.target.value;

    const previewUrl = `https://bot.zmashsolutions.com/preview/index.html?url=${encodeURIComponent(url)}&biid=${this.state.biid}`;

    this.setState({ url: event.target.value, previewUrl: previewUrl });
  }

  render() {
    const Link = withStyles({})(({ classes, label, ...props }) => (
      <Button className={classes.button} {...props}> {label}</Button>
    ));


    return (<div>
      <h2>Generate Preview Url</h2>
      <div>
        <div>
          <label>
            URL:
          </label>
        </div>
        <input type="text" value={this.state.url} onChange={this.handleChange} />
      </div>
      <div>
        <div>
          <label>
            Generated URL:
          </label>
        </div>
        <div>
          <textarea style={style} value={this.state.previewUrl} />
        </div>
        <div>
          <Link href={this.state.previewUrl} target="_blank" label="Open in new window" />
        </div>
      </div>
    </div>);
  }
}
export default withStyles({})(PreviewLink);
