import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
    button: {
        marginTop: '1em'
    }
};

const AddUserButton = ({ classes, record }) => (
    <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={`/user/create?clientId=${record.id}`}
    label="Add a User"
  >
  </Button>
);

export default withStyles(styles)(AddUserButton);
