import React from 'react';

class MessageWithLinkEditor extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action
      };
   }
   handleChange(event) {
      let { action } = this.state;
      action.content.messageContent = event.target.value;
      this.setState({ action: action });
   }
   handleCtaChange(event) {
      let { action } = this.state;
      action.content.messageLinkCta = event.target.value;
      this.setState({ action: action });
   }
   handleHrefChange(event) {
      let { action } = this.state;
      action.content.messageLinkHref = event.target.value;
      this.setState({ action: action });
   }
   getWithBr(message) {
      return message.replace(/(\r\n|\n|\r)/gm, "", "");
   }
   render() {
      let { action } = this.state;

      return (
         <div className="message-with-link">
            <div className="message-content">
               <textarea name="messageContent" 
               value={action.content.messageContent}
               onChange={this.handleChange.bind(this)}></textarea>
               <div className="hidden-textarea">{action.content.messageContent.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br/></span>;
                  })}
               </div>
            </div>
            <div className="message-extra">
               <input 
                  className="button-cta" 
                  onChange={this.handleCtaChange.bind(this)} 
                  value={action.content.messageLinkCta} 
               />
            </div>
            <div className="message-extra">
               <input 
                  className="link-href" 
                  onChange={this.handleHrefChange.bind(this)} 
                  value={action.content.messageLinkHref} 
               />
            </div>
         </div>
      );
   }
}
export default MessageWithLinkEditor;
