import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
    button: {
        marginTop: '1em'
    }
};

const SetTemplateButton = ({ classes, record }) => (
    <Button
        className={classes.button}
        variant="raised"
        component={Link}
        to={`/activeFlow/create?biid=${record.id}`}
        label="Set template flow"
    >
    </Button>
);

export default withStyles(styles)(SetTemplateButton);
