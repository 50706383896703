import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, ReferenceManyField, Tab, TabbedShowLayout, Datagrid, BooleanField,SelectField } from 'react-admin';
import Title from './Title';
import AddBotButton from './AddBotButton';
const botTypes = [
  { type: "Lead", value: "lead" },
  { type: "Application", value: "application" },
];

const ViewClient = (props) => (
  <Show {...props} title={<Title />}>
    <TabbedShowLayout>
        <Tab label="Summary">
          <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="projectNumber" />
            <TextField source="projectStatus" />
            <TextField source="type" />
            <TextField source="value" />
            <DateField label="StartDate" source="startDate" />
            <DateField label="EndDate" source="endDate" />
            <DateField label="Created" source="createdDate" />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Bot" path="bots">
          <ReferenceManyField
            addLabel={false}
            reference="bot"
            target="projectId"
          >
            <Datagrid rowClick="show">
              <TextField source="name" />
                <SelectField label="Bot type" source="type" 
                  choices={botTypes} optionText="type" 
                  optionValue="value" 
                />
            </Datagrid>
          </ReferenceManyField>
          <AddBotButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export default ViewClient;