import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, BooleanInput, FormDataConsumer, LongTextInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { parse } from "query-string";


const Title = ({ record }) => {
  const type = record.botType || 'application';
  return <span>Create a new {type} bot implementation</span>;
};

const choices = [
  { type: "JavaScript Client", value: "js" },
  { type: "Facebook Messenger Bot", value: "fb" },
];

const CreateBotImplementation = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { botId: BotId, botType: BotType } = parse(props.location.search);
  const redirect = BotId ? `/bot/${BotId}/show/botImplementations` : 'show';
  const df = { BotId: BotId };
  switch (BotType) {
    case 'lead':
      return LeadBotImplementationForm(redirect, df, props);
    case 'application':
      return ApplicationBotImplementationForm(redirect, df, props);
    default:
      return ApplicationBotImplementationForm(redirect, df, props);
  }
};

export default CreateBotImplementation;



const ApplicationBotImplementationForm = (redirect, df, props) => {
  return <Create {...props} title={<Title />}>
      <SimpleForm
          defaultValue={ df }
          redirect={ redirect }
      >
        <TextInput source="name" />
        
        <SelectInput label="Implementation Type" source="type" choices={choices} optionText="type" optionValue="value"/>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.type === 'fb' &&
          // we need a part for settting up and getting json starters for ad bots
          <div>
            <TextInput source="fbPageId" label= "Facebook Page Id" /><br />
            <BooleanInput label="Use NLP" source="useNlp" defaultValue={true} /><br />
            <TextInput source="fbPageAccessToken" label= "Facebook Page AccsessToken" /><br />
          </div>
        }
        </FormDataConsumer>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.type === 'js'  && 
          <div>
            <TextInput source="baseUrl" /><br />
            <TextInput source="callToAction" /><br />
            <TextInput source="headerImageUrl" /><br />
            <TextInput source="avatarImageUrl" /><br />
            <TextInput source="colorcode" /><br />
            <TextInput source="jobCtaText" label="Text for Apply button" /><br />
            <TextInput source="jobReadMoreCtaText" label="Text for Read More button" /><br />
            <BooleanInput label="Match URLs with Query String" source="matchQueryString" defaultValue={false} /><br />
            <BooleanInput label="Use NLP" source="useNlp" defaultValue={true} /><br />
          </div>
        }
        </FormDataConsumer>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.useNlp &&
          <div>
            <BooleanInput label="Use welcome message from Dialogflow" source="nlpStart" defaultValue={true} />
            <BooleanInput label="Disable Applications from NLP JobList" source="disableNlpApplications" defaultValue={false} />
          </div>
        }
        </FormDataConsumer>
        
        <FormDataConsumer>
         {({ formData, ...rest }) => !formData.nlpStart && formData.useNlp &&
            <ArrayInput source="welcomeMessages"  {...rest} >
                <SimpleFormIterator>
                  <LongTextInput source="message"/>
                </SimpleFormIterator>
            </ArrayInput>
         }
        </FormDataConsumer>
      
      </SimpleForm>
    </Create>
}

const LeadBotImplementationForm = (redirect, df, props) => {
  return <Create {...props} title={<Title />}>
      <SimpleForm
          defaultValue={ df }
          redirect={ redirect }
      >
        <TextInput source="name" />
        
        <SelectInput label="Implementation Type" source="type" choices={choices} optionText="type" optionValue="value"/>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.type === 'fb' &&
          // we need a part for settting up and getting json starters for ad bots
          <div>
            <TextInput source="fbPageId" label= "Facebook Page Id" /><br />
            <TextInput source="fbPageAccessToken" label= "Facebook Page AccsessToken" /><br />
          </div>
        }
        </FormDataConsumer>
        <FormDataConsumer>
        {({ formData, ...rest }) => formData.type === 'js'  && 
          <div>
            <TextInput source="callToAction" /><br />
            <TextInput source="headerImageUrl" /><br />
            <TextInput source="avatarImageUrl" /><br />
            <TextInput source="colorcode" /><br />
          </div>
        }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
}
