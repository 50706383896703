import React from 'react';
import { SelectInput } from 'react-admin';

const WorkoInput = ({ scopedFormData, name, label, dataProvider, source, ...rest }) => {
    let options = []
    console.log('workoField');
    let workoField = scopedFormData ? scopedFormData.workoField : '';
    if (workoField === 'preferences') {
        options = [
            { value: "Public welfare & sustainability", label: "Public welfare & sustainability" },
            { value: "Culture & Purpose", label: "Culture & Purpose" },
            { value: "Project", label: "Project" },
            { value: "Personal development", label: "Personal development" },
            { value: "Customers", label: "Customers" },
            { value: "Specialist Evironment", label: "Specialist Evironment" },
            { value: "Remote", label: "Remote" },
            { value: "Salary & Benefits", label: "Salary & Benefits" },
            { value: "Growth companies", label: "Growth companies" },
            { value: "Job security", label: "Job security" }
        ];
    }
    else if (workoField === 'preferredOrganizationSizes') {
        options = [
            { value: "Preferred Size Organization - Small", label: "Preferred Size Organization - Small" },
            { value: "Preferred Size Organization - Large", label: "Preferred Size Organization - Large" }
        ];
    }
    else if (workoField === 'preferredEmployeeTypes') {
        options = [
            { value: "Construction - Real Estate Developer", label: "Construction - Real Estate Developer" },
            { value: "Construction - Contractor", label: "Construction - Contractor" },
            { value: "Construction - Consultancy", label: "Construction - Consultancy" },
            { value: "IT - Consultancy", label: "IT - Consultancy" },
            { value: "IT - Tech Company", label: "IT - Tech Company" },
            { value: "IT - IT Department", label: "IT - IT Department" },
            { value: "Management - Consultancy", label: "Management - Consultancy" },
            { value: "Other", label: "Other" }
        ];
    }
    else if (workoField === 'industry') {
        options = [
            { value: "Construction - Real Estate Developer", label: "Construction - Real Estate Developer" },
            { value: "Construction - Contractor", label: "Construction - Contractor" },
            { value: "Construction - Consultancy", label: "Construction - Consultancy" },
            { value: "IT - Consultancy", label: "IT - Consultancy" },
            { value: "IT - Tech Company", label: "IT - Tech Company" },
            { value: "IT - IT Department", label: "IT - IT Department" },
            { value: "Management - Consultancy", label: "Management - Consultancy" },
            { value: "Other", label: "Other" }
        ];
    }
    else if (workoField === 'region') {
        options = [
            { value: "Stockholm", label: "Stockholm" },
            { value: "Göteborg", label: "Göteborg" },
            { value: "Malmö", label: "Malmö" },
            { value: "Oslo", label: "Oslo" },
            { value: "Bergen", label: "Bergen" },
            { value: "Stavanger", label: "Stavanger" },
            { value: "Trondheim", label: "Trondheim" },
            { value: "Other SE", label: "Other SE" },
            { value: "Other NO", label: "Other NO" }
        ];
    }



    return (
        <SelectInput
      source={source}
      choices={options}
      optionText="label"
      optionValue="value"
      label={label}
    />
    );
};

export default WorkoInput;
