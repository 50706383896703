import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
const Title = ({ record }) => {
    return <span>Job Categories</span>;
};

export const ListJobCategory = props => (
    <List {...props} title={<Title />}>
        <Datagrid rowClick="show">
            <TextField source="name" />
        </Datagrid>
    </List>
);
export default ListJobCategory;
