import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

class StepEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: this.props.step,
            tempStep: cloneDeep(this.props.step)
        };
    }
    handleChange(event) {
        let step = this.state.tempStep;
        step[event.target.name] = event.target.value;
        this.setState({ tempStep: step });
    }
    saveStep() {
        let { step, tempStep } = this.state;
        step = Object.assign(step,tempStep);
        this.setState({step: step});
        this.props.saveStep(this.state.step);
    }
    deleteStep() {
        this.props.deleteStep(this.state.step);
    }
    cancel() {
        this.setState({ tempStep: cloneDeep(this.props.step) });
        this.saveStep();
    }
    render() {
        let step = this.state.tempStep;

        return (
            <div>
                Stepname: <input
                              type="text"
                              name="name"
                              value={step.name}
                              onChange={this.handleChange.bind(this)}/>
                <br />
                StepOrder: <input
                              type="text"
                              name="stepNumber"
                              value={step.stepNumber}
                              onChange={this.handleChange.bind(this)}/>
                <br />
                <button onClick={this.saveStep.bind(this)} >Save Step</button>
                <button onClick={this.deleteStep.bind(this)} >Delete Step</button>
                <button onClick={this.cancel.bind(this)} >Cancel</button>
            </div>);
    }
}
export default StepEditor;
