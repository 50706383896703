import React from 'react';
import { Create , SimpleForm, TextInput, SelectInput, required  } from 'react-admin';
import Title from './Title';

const flowTypes = [
    { type: "Lead", value: "lead" },
    { type: "Application", value: "application" },
    { type: "Feedback", value: "feedback" },
];

const CreateFlow = (props) => (
    <Create  {...props} title={<Title />}>
        <SimpleForm redirect="list" >
            <TextInput source="name" />
            <SelectInput label="Flow Type" source="flowType" choices={flowTypes} optionText="type" optionValue="value" validate={[required()]} />
        </SimpleForm>
    </Create >
);

export default CreateFlow;
