import React from 'react';
import { Show, SimpleShowLayout, Button, TextField, DateField, EditButton, CardActions, Tab, TabbedShowLayout, SelectField } from 'react-admin';
import Title from './Title';
import { Link } from 'react-router-dom';
import languageChoices from '../../shared-components/languageCodes';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const FlowShowActions = ({ basePath, data, resource }) => {
  console.log(data)
  return (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
          {data && <Button
    variant="raised"
    color="primary"
    component={Link}
    to={`/flow-editor?flowVersionId=${data.id}`}
    label="Edit flow in Flow Editor"
  >
  </Button>}
    </CardActions>
);
}

const ViewFlowVersion = (props) => (
  <Show {...props} title={<Title />} actions={<FlowShowActions />}>
    <TabbedShowLayout>
        <Tab label="Summary">
          <SimpleShowLayout>
           <TextField source="name" />
            <TextField source="description" />
            <DateField label="Created" source="createdDate" />
            <SelectField label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export default ViewFlowVersion;

// <ShowButton />
