import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ArcherElement } from 'react-archer';

class PersonalDataConsent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action,
         actionIndex: this.props.actionIndex,
         node: this.props.node
      };
   }



   render() {
      let { action } = this.props;

      return (
         <div className="action personalData">
            <div className="message">
               GDPR Consent
            </div>
            <div className="answers">
               {action.content.answers.map((answer, index)=>{
                  return (
                  <div key={index} className="answer">
                     <span>{answer.answerContent}</span>
                  </div>);
               })}
            </div>
         </div>
      );
   }
}
export default PersonalDataConsent;
