import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
    button: {
        marginTop: '1em'
    }
};

const AddBotImplementationButton = ({ classes, record }) => (
    <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={`/botImplementation/create?botId=${record.id}${record.type ? '&botType=' + record.type: ''}`}
    label="Add a Bot Implementation"
  >
  </Button>
);

export default withStyles(styles)(AddBotImplementationButton);
