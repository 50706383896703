import React from 'react';
import { Create , SimpleForm, TextInput } from 'react-admin';
import Title from './Title';

const CreateClient = (props) => (
    <Create  {...props}  title={<Title />}>
        <SimpleForm redirect="list">
            <TextInput source="name" />
            <TextInput source="organizationNumber" />
            <TextInput label="Address - Street" source="address.street" />
            <TextInput label="Address - Postal Code" source="address.postalCode" />
            <TextInput label="Address - City" source="address.city" />
            <TextInput source="industry" />
        </SimpleForm>
    </Create >
);

export default CreateClient;
