import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Xarrow from "react-xarrows";


class GoToNode extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         action: this.props.action
      };
   }

   getRelations() {
      if (this.props.action.nextNodeId) {
         return [{
            targetId: this.props.action.nextNodeId,
            targetAnchor: 'left',
            sourceAnchor: 'right',
            style: {
               strokeColor: '#6656c2',
               strokeWidth: 2,
               endShape: { arrow: { arrowLength: 5 } },
            },
         }];
      }
      else {
         return [];
      }
   }

   getStyle(style, snapshot) {
      if (!snapshot.isDropAnimating) {
         return style;
      }
      return {
         ...style,
         // cannot be 0, but make it super tiny
         transitionDuration: `0.001s`,
      };
   }

   render() {
      let { action } = this.props;
      const uniqueId = (this.props.node.id + '_' + this.props.actionIndex);

      return (
         <div className="action goToNode">
            Go To Node
               <div className="out-anchor-wrapper">
                     <Draggable 
                        draggableId={uniqueId}
                        index={this.props.actionIndex}
                        type="pointer"
                     >
                        {(provided, snapshot) => (
                           <div
                           {...provided.draggableProps}
                           {...provided.dragHandleProps}
                           ref={provided.innerRef}
                           style={this.getStyle(provided.draggableProps.style, snapshot)}
                           className="out-anchor" data-next-node-id={action.nextNodeId}
                           id={uniqueId}>
                           { action.nextNodeId  &&

                              <Xarrow start={uniqueId} 
                               startAnchor="right" 
                               end={'node_'+action.nextNodeId}  
                               endAnchor="left"
                               strokeWidth={2}
                               color={"#6656c2"}
                               advanced={{
                                 passProps:{
                                   SVGcanvas: {
                                     "className":"xarrow"
                                   } 
                                 } 
                               }} />
                           }
                           </div>
                        )}
                     </Draggable>
               </div>
         </div>
      );
   }
}
export default GoToNode;
