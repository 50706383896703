import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import languageChoices from '../../shared-components/languageCodes';

import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  crudGetMatching,
  SaveButton,
  SimpleForm,
  TextInput,
  LongTextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  CREATE,
}
from 'react-admin';

import dataProvider from '../../providers/admin-api';



class PostQuickCreateButton extends Component {
  state = {
    error: false,
    created: false
  };



  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    const { submit, record } = this.props;
    console.log('click');
    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('job-quick-create-' + record.id);
  };

  handleSubmit = values => {
    this.setState({ isSubmitting: true });
    const {
      fetchStart,
      fetchEnd,
      showNotification
    } = this.props;
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    dataProvider(CREATE, 'job', { data: values })
      .then(({ data }) => {
        console.log('created');
        this.setState({ created: true, });

      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        this.setState({ isSubmitting: false });
        fetchEnd();
      });
  };

  render() {
    const { record } = this.props;
    const { isSubmitting, created } = this.state;
    
    const statusChoices = [
      { status: "Live" },
      { status: "Paused" },
      { status: "Archived" }
    ];
    return (
      <SimpleForm record={record}
          form={"job-quick-create-"+record.id}
          resource="job"
          onSubmit={this.handleSubmit}
          toolbar={null}
          saving={isSubmitting}
      >
      {!created && 
        (
          <div>
            <div>
              <TextInput source="title" validate={required()} />
              <TextInput source="city" validate={required()} />
              <ReferenceArrayInput label="Job categories" reference="jobCategory" source="jobCategories">
                <AutocompleteArrayInput optionText="name" optionValue="id" />
              </ReferenceArrayInput>
              <LongTextInput label="Link to job" source="link" />
              <BooleanInput source="useRegex" defaultValue={false} label="Use Regex for Linkmatching" />
              <BooleanInput source="overrideTemplate" defaultValue={false} />
              <BooleanInput source="disableApplications" defaultValue={false} />
              <SelectInput source="status" choices={statusChoices} optionText="status" optionValue="status" defaultValue="Live"/>

              <FormDataConsumer>
              {({ formData, ...rest }) => formData.overrideTemplate &&
              <ArrayInput source="overrideTemplates" label="Template Overrrides" {...rest}>
                <SimpleFormIterator>
                    <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
                    <ReferenceInput
                      source="flowVersionId"
                      reference="flowVersion"
                      label="Flow Version">
                      <SelectInput optionText="name" optionValue="FlowVersionId" />
                    </ReferenceInput >
                  </SimpleFormIterator>
              </ArrayInput>
              }
              </FormDataConsumer>
            </div>
            <SaveButton
                saving={isSubmitting}
                onClick={this.handleSaveClick}
            />
          </div>
        )
      }
      {this.state.created && (
        <div>
          <h2>Job Created</h2>
        </div>
      )
      }
          
      </SimpleForm>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('job-quick-create')(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(
  PostQuickCreateButton
);
