import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput, SelectInput, required } from 'react-admin';
import { parse } from "query-string";
import Title from './Title';

const botTypes = [
    { type: "Lead", value: "lead" },
    { type: "Application", value: "application" },
];

const CreateBot = props => {
    const { projectId: ProjectId } = parse(props.location.search);
    const redirect = ProjectId ? `/clientProject/${ProjectId}/show/bots` : 'show';
    const df = { ProjectId: ProjectId };
    return (
        <Create {...props} title={<Title />}>
            <SimpleForm
                defaultValue={ df }
                redirect={redirect}
            >
            <TextInput source="name" />
            <SelectInput label="BotType" source="type" choices={botTypes} optionText="type" optionValue="value" validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};

export default CreateBot;
