import React from 'react';

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: this.props.action
    };
  }
  handleChange(event) {
    let { action } = this.state;
    action.content.messageContent = event.target.value;
    this.setState({ action: action });
  }
  handleVariableChange(event) {
    let { action } = this.state;
    action.content.variable = event.target.value;
    this.setState({ action: action });
  }
  handleAnswerChange(event) {
    let { action } = this.state;
    action.content.answers[event.target.name].answerContent = event.target.value;
    this.setState({ action: action });
  }
  addAnswer(event) {
    let { action } = this.state;
    let answer = {
      answerContent: 'New Answer'
    };
    action.content.answers.push(answer);
    this.setState({ action: action });
  }
  removeAnswer(event) {
    let { action } = this.state;
    let i = event.target.getAttribute('data-index');
    action.content.answers.splice(Number(i), 1);
    this.setState({ action: action });
  }
  getWithBr(message) {
    return message.replace(/(\r\n|\n|\r)/gm, "", "");
  }

  render() {
    let { action } = this.state;

    return (
      <div className="question-contents">
        <div className="question-variable">
         <select id="variable" onChange={this.handleVariableChange.bind(this)} value={this.state.action.content.variable}>
          {this.props.variables.map((variable, i)=>{
            return <option key={i} value={variable}>{variable}</option>;
          }
          )}
         </select>
        </div>
        <div className="message-content">
           <textarea name="messageContent" 
           value={action.content.messageContent}
           onChange={this.handleChange.bind(this)}></textarea>
           <div className="hidden-textarea">
              {action.content.messageContent.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>;
              })}
           </div>
        </div>
        { action.content.answers && action.content.answers.length >0 && (
           <div className="answers">
              {action.content.answers.map((answer,i) => {
                 return (
                 <div key={i} className="answer-row">
                    <i className="material-icons" 
                      onClick={this.removeAnswer.bind(this)} 
                      data-index={i}>cancel</i>
                    <input 
                      className="answer" 
                      name={i} 
                      onChange={this.handleAnswerChange.bind(this)} 
                      value={answer.answerContent} 
                    />
                 </div>
                 );
              })}
           </div>
        )}
        <i onClick={this.addAnswer.bind(this)} 
          className="material-icons">add_circle_outline</i>
     </div>
    );
  }
}
export default Question;
