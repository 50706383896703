import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput,ArrayInput, SimpleFormIterator, DateInput, LongTextInput, BooleanInput, AutocompleteInput } from 'react-admin';
import { parse } from "query-string";
import Title from './Title';
import moment from 'moment-timezone';
import languageChoices from '../../shared-components/languageCodes';

const choices = [
  { type: "Career Solutions" },
  { type: "Recruitment Marketing" },
  { type: "Influencer Marketing" }
];
const metricsComparisonChoices = [
  { type: "Applications", value: 'applications' },
  { type: "Leads", value: 'leads' },
];

const statusChoices = [
  { status: "Not Started" },
  { status: "Live" },
  { status: "Paused" },
  { status: "Stopped" }
];
const timezones = moment.tz.names().map(tz => { return { timezone: tz } });

const CreateClientProject = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { clientId: ClientId } = parse(props.location.search);
  const redirect = ClientId ? `/client/${ClientId}/show/projects` : 'show';
  const df = { ClientId: ClientId };
  return (
    <Create {...props} title={<Title />}>
      <SimpleForm
          defaultValue={ df }
          redirect={redirect}
      >
        <TextInput source="name" />
        <SelectInput label="Project type" source="type" choices={choices} optionText="type" optionValue="type"/>
        <SelectInput label="Project Status" source="projectStatus" choices={statusChoices} optionText="status" optionValue="status"/>
        <TextInput label="Project Number" source="projectNumber" />
        <TextInput source="value" />
        <ArrayInput source="personalDataMessages">
          <SimpleFormIterator>
            <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
            <LongTextInput source="message"/>
          </SimpleFormIterator>
        </ArrayInput>
        <DateInput source="startDate" />
        <DateInput source="endDate" />
        <AutocompleteInput label="Analytics Timezone" source="analytics.timeZone" choices={timezones} optionText="timezone" optionValue="timezone" defaultValue="Europe/Stockholm"/>
          <BooleanInput source="hideProject" label="Hide Project" defaultValue={false} />
          <BooleanInput source="disableAiMetrics" label="Disable AI Metrics" defaultValue={false} />
          <BooleanInput source="disableApplicationMetrics" defaultValue={false} />
          <BooleanInput source="enableBetaFeatures" defaultValue={false} />
          <SelectInput label="Metrics Comparison" source="metricsComparison" choices={metricsComparisonChoices} optionText="type" optionValue="value"/>
      </SimpleForm>
    </Create>
  );
};

export default CreateClientProject;
