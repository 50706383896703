import React from 'react';
import { List, Datagrid, TextField, DateField, CardActions, RefreshButton } from 'react-admin';

const Title = ({ record }) => {
    return <span>Projects</span>;
};

const ClientProjectsActions = props => (
    <CardActions>
    
        <RefreshButton />
    </CardActions>
);
// <TextField source="ProjectId" />
export const ListClientProjects = props => (
    <List {...props} actions={<ClientProjectsActions />} title={<Title />} >
        <Datagrid rowClick="show" >
            <TextField label="Client" source="client.name" />
            <TextField source="name" />
            <TextField source="projectNumber" />
            <TextField source="projectStatus" />
            <TextField label="Project type" source="type" />
            <DateField source="startDate" />
            <DateField source="endDate" />
        </Datagrid>
    </List>
);
export default ListClientProjects;
