import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Xarrow from "react-xarrows";

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionIndex: this.props.actionIndex,
      node: this.props.node
    };
  }

  getRelations(answer) {
    if (answer.nextNodeId) {
      return [{
        targetId: answer.nextNodeId,
        targetAnchor: 'left',
        sourceAnchor: 'right',
        style: {
          strokeColor: '#6656c2',
          strokeWidth: 2,
          endShape: { arrow: { arrowLength: 5 } },
        },
      }];
    }
    else {
      return [];
    }
  }

  getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  }

  render() {
    let { action } = this.props;

    return (
      <div className="action question collect">
            <div className="variable">
               {action.content.variable}
            </div>
            <div className="message">
               {action.content.messageContent}
            </div>
            <div className="answers">
               {action.content.answers.map((answer, index)=>{
                  const uniqueId = (this.props.node.id +'_' + this.props.actionIndex +'_'+index);
                  return (
                  <div key={uniqueId} className="answer">
                    <div className="answer-button">
                      <span>{answer.answerContent}</span>
                        <div className="out-anchor-wrapper">
                              <Draggable 
                                 draggableId={uniqueId}
                                 index={index}
                                 type="pointer"
                              >
                                 {(provided, snapshot) => (
                                    <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    style={this.getStyle(provided.draggableProps.style, snapshot)}
                                    className="out-anchor" 
                                    data-next-node-id={answer.nextNodeId}
                                    id={uniqueId}>
                                    { answer.nextNodeId  &&

                                      <Xarrow start={uniqueId} 
                                        startAnchor="right" 
                                        end={'node_'+answer.nextNodeId}  
                                        endAnchor="left"
                                        strokeWidth={2}
                                        color={"#6656c2"}
                                        advanced={{
                                          passProps:{
                                            SVGcanvas: {
                                              "className":"xarrow"
                                            } 
                                          } 
                                        }} />
                                    }
                                    </div>
                                 )}
                              </Draggable>
                        </div>
                     </div>
                  </div>);
               })}
            </div>
         </div>
    );
  }
}
export default Question;
