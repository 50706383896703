import React from 'react';
import { Edit, SimpleForm, TextInput, ArrayInput, SimpleFormIterator, SelectInput, required  } from 'react-admin';
import Title from './Title';
import languageChoices from '../../shared-components/languageCodes';


const validateEntities = (values) => {
    const errors = {};
    // let hasSwedish = false;
    if (values) {
        if (!values.entities) {
            errors.entities = 'Missing entities';
        }
        if (values.entities && !values.entities.find(ent => ent.languageCode === 'sv')) {
            errors.entities = 'Missing Swedish name';
        }
    }
    return errors;
};

const EditJobCategory = (props) => (
    <Edit {...props} title={<Title />}>
        <SimpleForm validate={validateEntities}>
            <TextInput source="name" />
            <ArrayInput label="Entities" source="entities" {...props} >
                <SimpleFormIterator>
                    <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" validate={[required()]} />
                    <TextInput source="name"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export default EditJobCategory;
