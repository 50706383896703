import React from 'react';
import { Create, SimpleForm, TextInput, Link } from 'react-admin';
import { parse } from "query-string";
import Title from './Title';

const CreateUser = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { clientId: ClientId } = parse(props.location.search);
  const redirect = ClientId ? `/client/${ClientId}/show/users` : 'show';
  const df = ClientId ? { clientId: ClientId } : { clientId: 'admin' };
  const admin = ClientId ? false : true;
  return (
    <Create {...props} title={<Title />}>
            
      <SimpleForm
            defaultValue={ df }
            redirect={redirect}
      >
        { admin && (
            <div>
              <div>
                <strong>
                  WARNING! YOU ARE NOW CREATING AN ADMIN USER WHICH WILL HAVE ACCESS TO ALL PROJECTS FOR ALL CLIENTS. 
                  <br />
                  <br />
                  THIS TYPE OF USER IS MEANT FOR ZMASH EMPLOYEES ONLY.
                  <br />
                  <br />
                </strong>
                You can create a user for the client if by first selecting the client via the <Link to="/client">client section of the backoffice .</Link>                
              </div>
            <h2>Create Admin user</h2>
          </div> 
        )}
        <TextInput source="email" />
      </SimpleForm>
    </Create>
  );
};

export default CreateUser;
