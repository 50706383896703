// in src/dataProvider
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
}
from 'react-admin';
import omitEmpty from 'omit-empty';
import { stringify } from 'query-string';
import { Auth } from "aws-amplify";
import config from "../config.json";

const API_URL = process.env.NODE_ENV === 'development' ? config.api.devUrl : config.api.url;

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  switch (type) {
    case GET_LIST:
      {
        if (params.filter && params.filter.id) {
          const query = {
            filter: params.filter.id,
          };
          return { url: `${API_URL}/${resource}?${stringify(query)}` };
        }
        else if(params.filter && params.filter.botId && params.filter.integrationId){
          return { url: `${API_URL}/${resource}?botId=${params.filter.botId}&integrationId=${params.filter.integrationId}` };
        }
        else return { url: `${API_URL}/${resource}` };
      }
    case GET_ONE:
      return { url: `${API_URL}/${resource}/${params.id}` };
    case GET_MANY:
      {
        return { url: `${API_URL}/${resource}/${params.id}` };
      }
    case GET_MANY_REFERENCE:
      {
        // const { page, perPage } = params.pagination;
        // const { field, order } = params.sort;
        const query = {
          // filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
          filter: params.id,
        };
        return { url: `${API_URL}/${resource}?${stringify(query)}` };
      }
    case UPDATE:
      console.log(`${API_URL}/${resource}/${params.id}`);
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method: 'PUT', body: JSON.stringify(omitEmpty(params.data)) },
      };
    case CREATE:
      return {
        url: `${API_URL}/${resource}`,
        options: { method: 'POST', body: JSON.stringify(omitEmpty(params.data)) },
      };
    case DELETE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method: 'DELETE' },
      };
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  const { headers, json } = response;
  switch (type) {
    case GET_LIST:
      return {
        data: json.Items,
        total: json.Count
      };
    case GET_MANY_REFERENCE:
      return {
        data: json.Items,
        total: json.Count
      };
    case CREATE:
      if (resource === 'user') {
        alert("Temporary password for the user is: " + response.json.tempPassword);
      }
      return { data: { ...params.data, id: json.id } };
    default:
      return { data: json };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default async(type, resource, params) => {
  const { fetchJson } = fetchUtils;


  if (type === GET_MANY) {
    const headers = new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
    });

    return Promise.all(
      params.ids.map(id =>
        fetchJson(`${API_URL}/${resource}/${id}`, {
          method: 'GET',
          body: JSON.stringify(params.data),
          headers: headers
        })
      )
    ).then(responses => ({
      data: responses.map(response => response.json),
    }));
  }

  if (type === DELETE_MANY) {
    const headers = new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`
    });

    return Promise.all(
      params.ids.map(id =>
        fetchJson(`${API_URL}/${resource}/${id}`, {
          method: 'DELETE',
          body: JSON.stringify(params.data),
          headers: headers
        })
      )
    ).then(responses => ({
      data: responses.map(response => response.json),
    }));
  }
  let { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
  options = options || {};
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`);

  return fetchJson(url, options)
    .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};
