import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, BooleanInput, ReferenceInput, AutocompleteInput, FormDataConsumer } from 'react-admin';
import { parse } from "query-string";
import Title from './Title';
import { showNotification, GET_MANY_REFERENCE } from 'react-admin';
import dataProvider from '../../providers/admin-api';

// const choices = [
//   { type: "JavaScript Client", value: "js" },
// ];
// const getFlowVersionsFor = flowId => {
//   dataProvider(GET_MANY_REFERENCE, 'flowVersion', { id: flowId })
//     .then((data) => {
//       // showNotification('Comment approved');
//       // push('/comments');
//       console.log(data.data)
//       return data.data
//     })
//     .catch((e) => {
//       showNotification('Error: comment not approved', 'warning')
//     });
// }
const CreateActiveFlow = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { biid: biid } = parse(props.location.search);
  const redirect = biid ? `/botImplementation/${biid}/show/templateFlow` : 'show';
  const df = { biid: biid };
  return (
    <Create {...props} title={<Title />}>
      <SimpleForm
          defaultValue={ df }
          redirect={ redirect }
      >

      <ReferenceInput
        source="FlowVersionId"
        reference="flowVersion">
        <SelectInput optionText="name" />
      </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateActiveFlow;
  // <ReferenceInput
  //       source="FlowId"
  //       reference="flow">
  //       <AutocompleteInput optionText="name" />
  //     </ReferenceInput>
      
  //               <FormDataConsumer>
  //               {({ formData, ...rest }) =>
  //                   <SelectInput 
  //                       source="city"
  //                       choices={getFlowVersionsFor(formData.FlowId)}
  //                       {...rest}
  //                       optionText="name"
  //                   />
  //               }
  //           </FormDataConsumer>