import React from 'react';
import { List, Datagrid, TextField, CardActions, RefreshButton } from 'react-admin';

const Title = ({ record }) => {
    return <span>Flow Versions</span>;
};

const FlowVersionActions = props => (
    <CardActions>
        <RefreshButton />
    </CardActions>
);
// <TextField source="ProjectId" />
export const ListFlowVersion = props => (
    <List {...props} actions={<FlowVersionActions />} title={<Title />} pagination={false} perPage={300} >
        <Datagrid rowClick="show" >
            <TextField source="name" />
            <TextField source="description" />
        </Datagrid>
    </List>
);
export default ListFlowVersion;
