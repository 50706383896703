import React from 'react';
import { Edit, SimpleForm, TextInput, CloneButton, SelectInput } from 'react-admin';
import Title from './Title';
import languageChoices from '../../shared-components/languageCodes';

const EditFlowVersion = props => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm>
        <CloneButton />
        <TextInput source="name" />
        <TextInput source="description" />
        <SelectInput label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
      </SimpleForm>
    </Edit>
  );
};

export default EditFlowVersion;
