import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Message from './actions/MessageEditor';
import MessageWithLink from './actions/MessageWithLinkEditor';
import Question from './actions/QuestionEditor';
import SaveApplication from './actions/SaveApplicationEditor';
import SaveLead from './actions/SaveLeadEditor';
import SaveFeedback from './actions/SaveFeedbackEditor';
import CollectQuestionFixed from './actions/CollectQuestionFixedEditor';
import CollectQuestionFree from './actions/CollectQuestionFreeEditor';
import GoToNode from './actions/GoToNodeEditor';
import PersonalDataConsent from './actions/PersonalDataConsentEditor';

class NodeEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      node: this.props.editNode.node,
      tempNode: cloneDeep(this.props.editNode.node),
      step: this.props.editNode.step,
      editName: false
    };
    this.initialState = cloneDeep(this.state);
  }
  handleIdCheckboxChange(event, ) {
    let node = this.state.tempNode;
    node[event.target.name] = event.target.checked ? 'id' : 'regular';
    this.setState({ tempNode: node });
  }
  handleChange(event, checkbox = false) {
    let node = this.state.tempNode;
    node[event.target.name] = event.target.value;
    this.setState({ tempNode: node });
  }

  saveNode() {
    let { node, tempNode } = this.state;
    node.actions = cloneDeep(tempNode.actions);
    node.name = tempNode.name;
    node.type = tempNode.type || 'regular';
    this.setState({ node: node });
    this.props.saveStep();
  }

  deleteNode() {
    this.props.deleteNode();
  }

  cancel() {
    this.props.saveStep();
  }

  addMessageAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "message",
      content: {
        messageContent: "Hello"
      }
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }
  addMessageWithLinkAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "messageWithLink",
      content: {
        messageContent: "Hello",
        messageLinkCta: 'Click here',
        messageLinkHref: 'https://www.google.com'
      }
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }
  addQuestionAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "question",
      content: {
        messageContent: "Hello?",
        answers: [{
          answerContent: "Yes"
        }, {
          answerContent: "No"
        }]
      }
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }
  addCollectQuestionFixedAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "collectQuestionFixed",
      content: {
        messageContent: "Hello?",
        variable: 'email',
        answers: [{
          answerContent: "Yes"
        }, {
          answerContent: "No"
        }]
      }
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }
  addCollectQuestionFreeAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "collectQuestionFree",
      content: {
        messageContent: "Hello?",
        variable: 'email',
        answers: [{
          answerContent: "Free Text"
        }],
        quickReplies:[]
      }
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }
  addGoToNodeAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "goToNode",
      nextNodeId: null
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }
  addPersonalDataConsentAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "personalData",
      content: {
        answers: [{
          answerContent: "Jag accepterar"
        }]
      }
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }
  addSaveApplicationAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "saveApplication",
      content: {
        variables: [{
          name: "email"
        }, {
          name: "firstName"
        }]
      }
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }
  addSaveLeadAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "saveLead",
      content: {
        variables: [{
          name: "email"
        }]
      }
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }
  addSaveFeedbackAction() {
    let { tempNode } = this.state;
    let actions = tempNode.actions || [];

    actions.push({
      actionType: "saveFeedback",
      content: {
        variables: [{
          name: "email"
        }]
      }
    });
    this.setState({ tempNode: tempNode, addAction: false });
  }

  removeAction(event) {
    let { tempNode } = this.state;
    let actions = tempNode.actions;
    let i = event.target.getAttribute('data-index');
    actions.splice(Number(i), 1);
    this.setState({ tempNode: tempNode });
  }
  addAction() {
    this.setState({
      addAction: !this.state.addAction
    });
  }

  getActionComponent(action) {
    switch (action.actionType) {
      case 'message':
        return <Message action={action} />;
      case 'messageWithLink':
        return <MessageWithLink action={action} />;
      case 'question':
        return <Question action={action} />;
      case 'collectQuestionFixed':
        return <CollectQuestionFixed action={action} variables={this.props.variables} />;
      case 'collectQuestionFree':
        return <CollectQuestionFree action={action} variables={this.props.variables} />;
      case 'saveApplication':
        return <SaveApplication action={action} variables={this.props.variables} />;
      case 'saveLead':
        return <SaveLead action={action} variables={this.props.variables} />;
      case 'saveFeedback':
        return <SaveFeedback action={action} variables={this.props.variables} />;
      case 'goToNode':
        return <GoToNode action={action} />;
      case 'personalData':
        return <PersonalDataConsent action={action} />;
      default:
        return <Message action={action} />;
    }
  }
  getHeader(node) {
    return this.state.editName ? (
      <div className="header">
            <input value={node.name}
                name="name"
                onChange={this.handleChange.bind(this)}
                autoComplete="off"
            />
            <a className="save" onClick={this.toggleEditName.bind(this)}><i className="material-icons">check_circle_outline</i></a>
        </div>
    ) : (
      <div className="header">
            <h2 onClick={this.toggleEditName.bind(this)}>{node.name}</h2>
        </div>);
  }
  toggleEditName() {
    this.setState({ editName: !this.state.editName });
  }
  render() {
    let { tempNode, addAction } = this.state;
    let { flowType } = this.props;

    return (
      <div className="node-editor">
      <div className="editor-wrapper">
        {this.getHeader(tempNode)}
        <div className="properties">
        <h3>Properties</h3>
          <div>
            <label>ID node:</label><input type="checkbox" name="type" onChange={this.handleIdCheckboxChange.bind(this)} />
          </div>
        </div>
        <h3>Actions</h3>
          <div className="area actions">
          {tempNode.actions.map((action,i) => {
          return (<div key={i} className={"action " + action.actionType}>
                  {this.getActionComponent(action)}
                  <i className="material-icons" onClick={this.removeAction.bind(this)} data-index={i}>cancel</i>
                  </div>)}
          )}
          {(tempNode.actions.length === 0) && (
            <div className="no-actions subtle">
              No actions at the moment, add new ones from the menu below
            </div>
          )}
          </div>
        </div>
        <div className="menu">
        {addAction && (
          <nav className="action-menu">
            <ul>
              <li>
                <a onClick={this.addMessageAction.bind(this)}>
                    <i className="material-icons">message</i>
                    <span>Message</span>
                </a>
              </li>
              <li>
                <a onClick={this.addMessageWithLinkAction.bind(this)}>
                    <i className="material-icons">message</i>
                    <span>Message w/ link</span>
                </a>
              </li>
              <li>
                <a onClick={this.addQuestionAction.bind(this)}>
                    <i className="material-icons">question_answer</i>
                    <span>Question</span>
                </a>
              </li>
              <li>
                <a onClick={this.addCollectQuestionFixedAction.bind(this)}>
                    <i className="material-icons">question_answer</i>
                    <span>CF - Question - Fixed</span>
                </a>
              </li>
              <li>
                <a onClick={this.addCollectQuestionFreeAction.bind(this)}>
                    <i className="material-icons">question_answer</i>
                    <span>CF - Question - Free</span>
                </a>
              </li>
              <li>
                <a onClick={this.addGoToNodeAction.bind(this)}>
                    <i className="material-icons">subdirectory_arrow_right</i>
                    <span>Go to Node</span>
                </a>
              </li>
              <li>
                <a onClick={this.addPersonalDataConsentAction.bind(this)}>
                    <i className="material-icons">book</i>
                    <span>GDPR Consent</span>
                </a>
              </li>
              { !flowType || flowType === 'application' && (
              <li>
                <a onClick={this.addSaveApplicationAction.bind(this)}>
                    <i className="material-icons">timelapse</i>
                    <span>Save Application</span>
                </a>
              </li>
              )}
              { flowType === 'lead' && (
              <li>
                <a onClick={this.addSaveLeadAction.bind(this)}>
                  <i className="material-icons">timelapse</i>
                  <span>Save Lead</span>
                </a>
              </li>
              )}
              { flowType === 'feedback' && (
              <li>
                <a onClick={this.addSaveFeedbackAction.bind(this)}>
                  <i className="material-icons">timelapse</i>
                  <span>Save Feedback</span>
                </a>
              </li>
              )}
                
            </ul>
          </nav>
        )}
        <nav className="main-menu">
          <ul className="square">
            <li className="red"><a onClick={this.deleteNode.bind(this)}><i className="material-icons">delete_forever</i></a></li>
            <li><a onClick={this.cancel.bind(this)}><i className="material-icons">cancel</i></a></li>
          </ul>
          <ul> 
            <li><a onClick={this.addAction.bind(this)}><i className="material-icons">add_circle_outline</i><span>Add Action</span></a></li>
            <li className="save"><a onClick={this.saveNode.bind(this)}><i className="material-icons">check_circle_outline</i><span>Save</span></a></li>
          </ul>
        </nav>
      </div>
  </div>);
  }
}
export default NodeEditor;
