import React from 'react';
import Button from '@material-ui/core/Button';
import {
  ShowController,
  Show,
  FormDataConsumer,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  DateField,
  BooleanField,
  SelectField,
  Tab,
  TabbedShowLayout,
  DeleteButton,
  ArrayField,
  ReferenceManyField,
  SingleFieldList,
  Datagrid,
  Labeled,
  CardActions,
  EditButton,
  ArrayInput,
  SimpleFormIterator,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput
}
from 'react-admin';
import Title from './Title';
import PreviewLink from './PreviewLink';
import SetTemplateButton from './SetTemplateButton';
import queryString from 'query-string';
import { Auth, API } from 'aws-amplify';
import config from "../../config.json";
import languageChoices from '../../shared-components/languageCodes';

const API_URL = process.env.NODE_ENV === 'development' ? config.api.devUrl : config.api.url;
const apiName = process.env.NODE_ENV === 'development' ? config.api.apiNameDev : config.api.apiNameProd;

const style = { border: '2px solid #aaa', width: '70em', height: '15em', fontSize: '10px' };

const choices = [
  { type: "JavaScript Client", value: "js" },
  { type: "Facebook Bot", value: "fb" },
];


const script =
  `<!-- Zmashbot Script Start -->
<script>
(function(z,m,a,s,h,b,o,t,c,ha,tb,ot,sc,ri,pt){z=z[s]=z[s]||{};z.biid=h;z.type=sc;
s=m.getElementsByTagName(a)[0];m=m.createElement(a);m.async=!0;m.src="https://bot.zmashsolutions.com/bot.js";z.himg=b||null;z.aimg=o||null;s.parentNode.insertBefore(m,s);
z.cc=t||null;z.cta=c||null;z.ao=ha||!1;z.ani=tb.a||!1;z.ctaBg=tb.b||null;z.ctaCc=tb.c||null;z.dlp=ot;z.zIndex=ri;z.initParams=pt||null})
(window,document,'script','zmashBot','%%biid%%',
'%%himg%%','%%aimg%%',
'%%cc%%','%%cta%%',%%ao%%,{a:%%ani%%,b:%%ctaBg%%,c:%%ctaCc%%},%%dlp%%,'%%type%%',%%zIndex%%,{});
</script>
<!-- Zmashbot Script End -->`;
const scriptWithPlaceHolders =
  `<!-- Zmashbot Script Start -->
<script>
(function(z,m,a,s,h,b,o,t,c,ha,tb,ot,sc,ri,pt){z=z[s]=z[s]||{};z.biid=h;z.type=sc;
s=m.getElementsByTagName(a)[0];m=m.createElement(a);m.async=!0;m.src="https://bot.zmashsolutions.com/bot.js";z.himg=b||null;z.aimg=o||null;s.parentNode.insertBefore(m,s);
z.cc=t||null;z.cta=c||null;z.ao=ha||!1;z.ani=tb.a||!1;z.ctaBg=tb.b||null;z.ctaCc=tb.c||null;z.dlp=ot;z.zIndex=ri;z.initParams=pt||null})
(window,document,'script','zmashBot','%%biid%%',
'%%himg%%','%%aimg%%',
'%%cc%%','%%cta%%',%%ao%%,{a:%%ani%%,b:%%ctaBg%%,c:%%ctaCc%%},%%dlp%%,'%%type%%',%%zIndex%%, {lang:'<LANGUAGE CODE HERE>',jobId:"<JOB ID HERE>"});
</script>
<!-- Zmashbot Script End -->`;

const BotScriptField = (props) => {
  console.log(props)
  let { record, withInitParams } = props
  let val = '';
  val += withInitParams ? scriptWithPlaceHolders : script;
  val = val.replace('%%biid%%', record.biid);
  val = val.replace("'%%aimg%%'", record.avatarImageUrl ? "'" + record.avatarImageUrl + "'" : null);
  val = val.replace("'%%himg%%'", record.headerImageUrl ? "'" + record.headerImageUrl + "'" : null);
  val = val.replace("'%%cc%%'", record.colorcode ? "'" + record.colorcode + "'" : null);
  val = val.replace('%%cta%%', record.callToAction);
  val = val.replace('%%biid%%', record.biid);
  val = val.replace('%%ao%%', (record.autoOpen) ? `{d:${record.autoOpen.desktop ? 1: 0},m:${record.autoOpen.mobile ? 1:0},scc:${record.autoOpen.sessionCookie ? 1:0}}` : `{d:0,m:0,scc:${record.autoOpen && record.autoOpen.sessionCookie ? 1:0}}`);
  val = val.replace('%%ani%%', record.animate ? true : false);
  val = val.replace('%%ctaBg%%', record.ctaBg ? "'" + record.ctaBg + "'" : null);
  val = val.replace('%%ctaCc%%', record.ctaCc ? "'" + record.ctaCc + "'" : null);
  val = val.replace('%%dlp%%', record.pushToDataLayer && record.pushToDataLayer.shouldPush ? 1 : 0);
  val = val.replace('%%type%%', record.bot.type !== 'lead' ? 'a' : 'l');
  val = val.replace('%%zIndex%%', record.zIndex !== undefined ? Number(record.zIndex) : null);
  return (
    <textarea style={style} defaultValue={val}>
      
    </textarea>
  );
};

BotScriptField.defaultProps = { label: 'Script', addLabel: true };

const StartWithNlpField = ({ record, ...rest }) =>
  record.useNlp ? (
    <Labeled label="Use welcome message from Dialogflow">
      <BooleanField source="nlpStart" record={record} {...rest} />
    </Labeled>
  ) : null;

const DisableNlpApplicationsField = ({ record, ...rest }) =>
  record.useNlp ? (
    <Labeled label="Disable Applications from NLP JobList">
      <BooleanField source="disableNlpApplications" record={record} {...rest} />
    </Labeled>
  ) : null;

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};
const PostShowActions = ({ basePath, data, resource }) => (
  <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        
    </CardActions>
);
const setProfile = (operation, data) => {
  if (data && data.messengerProfile && data.fbPageAccessToken) {

    API.post(apiName, `/setFacebookProfile/${data.id}`, {
        body: {
          "operation": operation
        }
      })
      .then(response => {
        console.log(response);
      });
  }
};
const ViewBotImplementation = (props) => (
  <ShowController {...props} title={<Title />} >
    {controllerProps => <Show { ...props } { ...controllerProps}  title={<Title />} actions={<PostShowActions />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          {controllerProps.record && controllerProps.record.bot && controllerProps.record.bot.type !== 'lead' && ApplicationBotImplementationForm(controllerProps) }
          {controllerProps.record && controllerProps.record.bot && controllerProps.record.bot.type === 'lead' && LeadBotImplementationForm(controllerProps) }
          <SimpleShowLayout>

            <h5 style={{'margin':'.5em 0 1em 0'}}>Script</h5>
              <h2>BotImplementation Script</h2>
            {controllerProps.record && controllerProps.record.type && controllerProps.record.type === 'js' &&
              <BotScriptField source="biid"/>
            }
              <h2>BotImplementation Script with integration initialization placeholders</h2>
            {controllerProps.record && controllerProps.record.type && controllerProps.record.type === 'js' &&
              <BotScriptField source="biid" withInitParams={true}/>
            }
            {controllerProps.record && controllerProps.record.type && controllerProps.record.type === 'js' &&
              <PreviewLink />
            }
            <DateField label="Created" source="createdDate" />
          </SimpleShowLayout>
        </Tab>
        {controllerProps.record && controllerProps.record.type && controllerProps.record.type === 'fb' &&
        <Tab label="Facebook Messenger Tools">
          <FacebookTools props={controllerProps} />
        </Tab>
        }
      </TabbedShowLayout>
    </Show>
    }
  </ShowController>
);

export default ViewBotImplementation;

const getJobPostBack = (flowId, jobId, botId) => {
  return `START_FLOW__FlowId=${flowId}&JobId=${jobId}&BotId=${botId}`

}
const getStartFlowPostBack = (flowId) => {
  return `START_FLOW__FlowId=${flowId}`;

}

const getStructuredJson = (botId, data) => {

  if (data.message) {
    if (data.ctas && data.ctas.length > 0) {

      let quickReplies = data.ctas.filter(cta => {
        if (cta.ctaType && cta.flow && cta.ctaText) {
          if (cta.ctaType === 'application') {
            if (cta.job) {
              return true;
            }
            else return false;
          }
          else if (cta.ctaType === 'startFlow') {
            return true;
          }
          else return false;
        }
        else return false;
      }).map((cta, i) => {
        return {
          "content_type": "text",
          "title": cta.ctaText.substring(0, 20).replace('.', ' '), // facebook has a 20 char limit
          "payload": cta.ctaType === 'application' ? getJobPostBack(cta.flow, cta.job, botId) : getStartFlowPostBack(cta.flow)
        };
      });
      if (quickReplies.length > 0) {
        return JSON.stringify({
          "message": {
            "text": data.message,
            "quick_replies": quickReplies
          }
        }, null, 2);
      }
      else return JSON.stringify({
        "message": {
          "text": data.message,
        }
      }, null, 2);

    }
    else return JSON.stringify({
      "message": {
        "text": data.message,
      }
    }, null, 2);

  }
  else return '';
};

const getMessengerActions = (bot) => {
  let botType = bot.type || 'application';
  let choices = [{ id: 'startFlow', name: 'Start Flow' }];

  if (botType === 'lead') {
    return choices;
  }
  else {
    choices.push({ id: 'application', name: 'Start Application' });
    return choices;
  }
};

const FacebookTools = (props) => {
  // let fbGen = { "BotId": props.record.BotId };
  console.log(props.record)
  return <div>
    <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
      <h3>
      Facebook profile
      </h3>
      <Button color="primary" onClick={()=>setProfile("setGetStartedFlow", props.record)}>Set "Get Started Button"</Button>
      <Button color="primary" onClick={()=>setProfile("removeGetStartedFlow", props.record)}>Remove "Get Started Button"</Button>
      <Button color="primary" onClick={()=>setProfile("setGreetings", props.record)}>Set Greetings</Button>
      <Button color="primary" onClick={()=>setProfile("removeGreetings", props.record)}>Remove Greetings</Button>
      <Button color="primary" onClick={()=>setProfile("setIceBreakers", props.record)}>Set Ice Breakers</Button>
      <Button color="primary" onClick={()=>setProfile("removeIceBreakers", props.record)}>Remove Ice Breakers</Button>
    </div>
    <div style={{display:'block', background: '#f0f0f0', padding:"1em 2em", borderRadius: ".5em", "margin": "1em 0"}}>
      <h3>
      JSON Generator
      </h3>
      
      <SimpleForm submitOnEnter={false} toolbar={false} >
        <TextInput source="message" />
        
        <ArrayInput source="ctas"  {...props} label="Call to Actions">
            <SimpleFormIterator>
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => { return(
                    <div>
                      <TextInput source={getSource("ctaText")} label="CTA text"/>
                        <RadioButtonGroupInput source={getSource('ctaType') } choices={getMessengerActions(props.record.bot)} label="action" />
                      {scopedFormData && scopedFormData.ctaType && scopedFormData.ctaType === "application" &&
                        <div>
                        <ReferenceInput
                          addLabel={false}
                          reference="job"
                          source={getSource("job")}
                          filter={ {id: props.record.BotId }}
                          label="Job"
                        >
                        <SelectInput optionText="title" />
                      </ReferenceInput>
                        </div>
                      }
                      <ReferenceInput
                        source={getSource("flow")}
                        reference="flowVersion"
                        label="Flow Version"
                        >
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    </div>
                  )}}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
        
        <FormDataConsumer>
          {({ formData, ...rst }) =>{
              if (formData && formData.message) {
                  return <div>
                  <h4>JSON</h4>
                    <textarea style={style} value={getStructuredJson(props.record.BotId, formData) || ''}></textarea>
                  </div>
              }
          }}
        </FormDataConsumer>

        
      </SimpleForm>
    </div>
  </div>;


}
// <TextInput source="fbgen.test" />
const ApplicationBotImplementationForm = (props) => {
  return <SimpleShowLayout {...props} >
    <TextField source="name"/>
    <SelectField label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
    <SelectField label="Implementation Type" source="type" choices={choices} optionText="type" optionValue="value"/>
    {props.record.type === 'fb' &&
      // we need a part for settting up and getting json starters for ad bots
      <SimpleShowLayout>
        <TextField source="fbPageId" label= "Facebook Page Id" /><br />
        <BooleanField label="Use NLP" source="useNlp" defaultValue={true} /><br />
        <TextField source="fbPageAccessToken" label= "Facebook Page Access Token" /><br />
      </SimpleShowLayout>
    }
    
    {props.record.type === 'js'  && 
      <SimpleShowLayout>
        <TextField source="baseUrl" /><br />
        <BooleanField label="Match URLs with Query String" source="matchQueryString" defaultValue={false} /><br />

        <BooleanField label="Perform DataLayer pushes to GTM" source="pushToDataLayer.shouldPush" defaultValue={false} />
        {props.record && props.record.pushToDataLayer && props.record.pushToDataLayer.shouldPush &&
          <BooleanField label="Hashed Email as Data Layer Variable" source="pushToDataLayer.hashedEmail" defaultValue={false} />
        }
      <BooleanField label="Use NLP" source="useNlp" defaultValue={true} /><br />
      </SimpleShowLayout>
    }
    
      {props.record && props.record.type && props.record.type === 'js' &&
        <SimpleShowLayout>
          <h5 style={{'margin':'.5em 0 1em 0'}}>Look & feel</h5>
          <TextField source="headerImageUrl" />
          <TextField source="avatarImageUrl" />
          <TextField source="colorcode" label="Primary colorcode" />
          <TextField source="ctaBg" label="CTA Bubble - Background color code" />
          <TextField source="ctaCc" label="CTA Bubble - Text color code" />
          <BooleanField source="autoOpen.desktop" defaultValue={false} label="Auto open - Desktop"/>
          <BooleanField source="autoOpen.mobile" defaultValue={false} label="Auto open - Mobile"/>
          <BooleanField source="autoOpen.sessionCookie" defaultValue={false} label="Don't Auto Open again in the same session"/>
          <BooleanField source="animate" defaultValue={false} />
        </SimpleShowLayout>
    }
        {props.record && props.record.type && props.record.type === 'js' &&
        <SimpleShowLayout>
          <h5 style={{'margin':'.5em 0 1em 0'}}>Copy</h5>
          <TextField source="callToAction" />
          <TextField source="jobCtaText" label="Text for Apply button" />
          <TextField source="jobReadMoreCtaText" label="Text for Read More button" />
        </SimpleShowLayout>

        }
    
    <h5 style={{'margin':'.5em 0 1em 0'}}>NLP settings</h5>

    {props.record.useNlp &&
      <SimpleShowLayout>
        <BooleanField label="Use welcome message from Dialogflow" source="nlpStart" defaultValue={true} />
        <BooleanField label="Disable Applications from NLP JobList" source="disableNlpApplications" defaultValue={false} />
      </SimpleShowLayout>
    }
    {!props.record.nlpStart && props.record.useNlp &&
        <ArrayField source="welcomeMessages">
            <SingleFieldList>
              <TextField source="message"/>
            </SingleFieldList>
        </ArrayField>
    }
      {props.record && props.record.introStart && props.record.useNlp &&
          <SimpleShowLayout>
            <h6 style={{'margin':'.5em 0 1em 0'}}>Intro settings</h6>
            <TextField source="intro.messageContent" label="Intro message"/>
            <TextField source="intro.ctaNlp" label="CTA for NLP"/>
            <TextField source="intro.ctaFlow" label="CTA for job flow" />
            {props.record.intro && props.record.job &&
              
            <ReferenceManyField
              source="intro.job"
              reference="job"
              filter={{id:props.record.BotId}}
              label="Job"
            >
              <SelectField optionText="title" optionValue="JobId" />
            </ReferenceManyField>
            }
          </SimpleShowLayout>
      }
      <BooleanField label="Use welcome-back messages" source="welcomeBack.enabled" />
    </SimpleShowLayout>
}



const LeadBotImplementationForm = (props) => {
  return <SimpleShowLayout {...props}>
    <h5 style={{'margin':'.5em 0 1em 0'}} >Basic properties</h5>

    <TextField source="name" />
    <SelectField label="Language" source="languageCode" choices={languageChoices} optionText="lang" optionValue="value" />
    <SelectField label="Implementation Type" source="type" choices={choices} optionText="type" optionValue="value"/>
    
    {props.record.type === 'fb' &&
      // we need a part for settting up and getting json starters for ad bots
      <SimpleShowLayout>
        <TextField source="fbPageId" label= "Facebook Page Id" /><br />
        <TextField source="fbPageAccessToken" label= "Facebook Page Access Token" /><br />
      </SimpleShowLayout>
    }
    
    {props.record.type === 'js'  && 
      <SimpleShowLayout>
        <TextField source="baseUrl" /><br />

        <BooleanField label="Perform DataLayer pushes to GTM" source="pushToDataLayer.shouldPush" defaultValue={false} />
        {props.record && props.record.pushToDataLayer && props.record.pushToDataLayer.shouldPush &&
          <BooleanField label="Hashed Email as Data Layer Variable" source="pushToDataLayer.hashedEmail" defaultValue={false} />
        }
      </SimpleShowLayout>
    }
    
    {props.record && props.record.type && props.record.type === 'js' &&
        <SimpleShowLayout>
          <h5 style={{'margin':'.5em 0 1em 0'}}>Look & feel</h5>
          <TextField source="headerImageUrl" />
          <TextField source="avatarImageUrl" />
          <TextField source="colorcode" label="Primary colorcode" />
          <TextField source="ctaBg" label="CTA Bubble - Background color code" />
          <TextField source="ctaCc" label="CTA Bubble - Text color code" />
          <BooleanField source="autoOpen.desktop" defaultValue={false} label="Auto open - Desktop"/>
          <BooleanField source="autoOpen.mobile" defaultValue={false} label="Auto open - Mobile"/>
          <BooleanField source="autoOpen.sessionCookie" defaultValue={false} label="Don't Auto Open again in the same session"/>
          <BooleanField source="animate" defaultValue={false} />
        </SimpleShowLayout>
      }
      {props.record && props.record.type && props.record.type === 'js' &&
        <SimpleShowLayout>
          <h5 style={{'margin':'.5em 0 1em 0'}}>Copy</h5>
          <TextField source="callToAction" />
        </SimpleShowLayout>
      }
  </SimpleShowLayout>;
};
